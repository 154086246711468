import React from 'react'
import { withRouter } from "react-router-dom"
import { openUrl } from '@tool'
import './styles.less' 

const FreechargePage = ({ history }) => {

  return (
    <div className="freechargePage">
      <img src="https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/freecharge/freecharge.png" className='bgImage' alt="" />
      <div className='supportButn' onClick={() => {
        openUrl("https://support.futures.cash/hc/en-us/requests/new")
      }}></div>
      <div className='infoButn' onClick={() => {
        openUrl("https://futuresfoundation.medium.com/futurescash-swap-time-limited-campaign-free-handling-fee-6830a8d74532")
      }}></div>
      {/* <div className='swapButnContainer'>
        <div className='swapButn'>Swap Now</div>
      </div> */}
    </div>
  )
}
export default withRouter(FreechargePage)