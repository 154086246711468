// eslint-disable-next-line
import React, { useEffect } from 'react'
import {withRouter} from "react-router-dom"
import {Button} from 'antd-mobile'
import {constant} from "@json"
import {getQueryVariable} from '@tool'
import { useTranslation } from "react-i18next"
import './styles.less'

const JoinPage = ({history}) => {
    const { t, i18n } = useTranslation()
    const data = constant.join
    const code = !getQueryVariable("code") ? "-" : getQueryVariable("code")
    const lang = getQueryVariable("lang") === "zh" ? "zh" : "en"
    i18n.changeLanguage(lang) 

    // eslint-disable-next-line
    function go() {
        window.open("https://support.futures.cash/hc/en-us/articles/10649553372185-FuturesCash-Premium-")
    }

    return (
        <div className="join-page">
            <img src={data.join} alt="" className="bg-image"/>
            <div className="head-container">
                <img src={data.logo} alt="" className="logo"/>
                <div className="push-container" onClick={() => window.open("https://futures.cash/")}>
                    <p className="website">{t("View Official Website")}</p>
                    <img src={data.arrow} alt="" className="arrow"/>
                </div>
            </div>
            <div className="body-container">
                <p className="title">{`👋 ${t("Hi! Join FuturesCash to Earn together!")}`}</p>
                <div className="content">
                    <span className="bold">{code}</span> {t("invites you to join FuturesCash. Enjoy free swapping, earn crypto on a daily basis, and spend your crypto in real life.")}
                    {/* <br/>
                    Enjoy FREE swap and FREE withdrawal.
                    <br/>
                    <p onClick={() => {go()}} style={{color:"#FF7700"}}>
                        Additional terms and conditions apply
                    </p> */}
                </div>
                <Button className="button" onClick={() => {
                    history.push({
                        pathname: "/register",
                        state: {
                            code: code
                        }
                    })
                }}>{t("Sign up")}</Button>
            </div>
        </div>
    )
}
export default withRouter(JoinPage)