import React, { 
  useState,
  useEffect
} from 'react'
import { withRouter } from "react-router-dom"
import { constant } from "@json"
import { Button, Mask, Toast } from 'antd-mobile'
import { post } from '@service'
import { getQueryVariable } from '@tool'
import './styles.less'

const USERSTATUS = {
  "default": 1,
  "attend": 2,
  "edit": 3,
}

const Private2Page = ({ history }) => {
  const data = constant.private2
  const userId = getQueryVariable("userId")
  const [userStatus, setUserStatus] = useState(USERSTATUS.default)
  const [process, setProcess] = useState(0)
  const [accountValue, setAccountValue] = useState("")
  const [u, setU] = useState("0")
  const [fctValue, setFctValue] = useState("")
  const [submit, setSubmit] = useState(true)
  const [maskVisible, setMaskVisible] = useState(false)
  const [emailValue, setEmailValue] = useState('')
  const [emailConfirm, setEmailConfirm] = useState(true)
  const [paymentValue, setPaymentValue] = useState('')
  const [paymentConfirm, setPaymentConfirm] = useState(true)
  const [congratulateContent, setCongratulateContent] = useState("")

  useEffect(() => {
    if (!!userId) {
      getData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  async function getData() {
    try {
      const resp = await post(`fct/supplyInfo2?userId=${userId}`)
      const _number = !!resp.number && parseInt(resp.number) > 0 ? parseInt(resp.number) : ""
      const _email = !!resp.email ? resp.email : ""
      const _userStatus = !!_number ? USERSTATUS.attend : USERSTATUS.default
      setAccountValue(_number)
      setEmailValue(_email)
      setUserStatus(_userStatus)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error.message.toString(),
      })
    }
  }

  async function bandingEmail() {
    if (!emailValue) {
      Toast.show({content: "Enter your email"})
    } else {
      try {
        await post(`fct/bandingEmail?userId=${userId}`, {
          "email": emailValue
        })
        Toast.show({
          icon: 'success',
          content: 'Thank you for your interest. We will be connecting to you shortly',
        })
        setMaskVisible(false)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      } 
    }
  }
  
  useEffect(() => {
    const _fctValue = !!accountValue ? "FCT" : ""
    setFctValue(_fctValue)
    const _u = !!accountValue ? parseFloat((parseFloat(accountValue) * 0.1).toFixed(1)) : "0"
    setU(_u)
    const _submit = !!accountValue ? false : true
    setSubmit(_submit)
    const _congratulateContent = `${data.congratulateContentPrefix}<span class="lightText"> ${_u} USDT </span>${data.congratulateContentSuffix}`
    setCongratulateContent(_congratulateContent)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountValue])

  useEffect(() => {
    const _emailConfirm = !!emailValue ? false : true
    setEmailConfirm(_emailConfirm)
  }, [emailValue])

  useEffect(() => {
    const _paymentConfirm = !!paymentValue ? false : true
    setPaymentConfirm(_paymentConfirm)
  }, [paymentValue])

  function tagInSubmitButn() {
    var account = parseInt(accountValue)
    if (account > 0 && account < 1000) {
      Toast.show({content: data.warnMinMax})
      return
    } else if (account > 100000) {
      Toast.show({content: data.warnMinMax})
      return
    } else if (account < 0) {
      Toast.show({content: data.warnMinMax})
      return
    } else {
      setProcess(1)
      setMaskVisible(true)
      setTimeout(function(){
        document.getElementById('payment').focus()
      }, 500)
    }
  }
  async function tagInEnterPaymentConfirm() {
    if (!paymentValue) {
      Toast.show({content: "Enter Payment PIN to verify"})
    } else {
      try {
        await post(`fct/supply2?userId=${userId}`, {
          "number": accountValue,
          "paymentPassword": paymentValue,
        })
        Toast.show({
          icon: 'success',
          content: 'Success',
        })
        setMaskVisible(false)
        getData()
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    }
  }

  function tagInApplyWarn() {
    setProcess(0)
    setMaskVisible(true)
    setTimeout(function(){
      document.getElementById('email').focus()
    }, 500)
  }

  function accountInputOnChange(e) {
    var value = e.target.value
    if (parseInt(value) > 100000 || parseInt(value) < 0) {
      Toast.show({content: data.warnMinMax})
      return
    } else {
      setAccountValue(value)
    }
  }

  function afterCloseMask() {
    setProcess(0)
    setPaymentValue('')
  }
  
  return (
    <div className="private2Page">
      <img src={data.headImage} alt="" className="headImage" />
      <div className="bodyContainer">
        <img src={data.logoIcon} alt="" className="logoIcon" />
        <ul className="dataList">
        {
          data.lists.map((item, index) => {
            return <li className="listItem" key={index}>
              <p className="key">{item.key}</p>
              <p className="value">{item.value}</p>
            </li>
          })
        }
        </ul>
        <p className="contentDesc" dangerouslySetInnerHTML={{__html: data.contentDesc}}></p>
        <div className="accountInputContainer">
          <input className="accountInputDiv" type='number' disabled={userStatus === USERSTATUS.attend} placeholder='Enter the FCT amount you need' value={accountValue} onChange={accountInputOnChange}/>
          <p className={`inputUnit ${userStatus === USERSTATUS.attend ? 'diasbleInputUnit' : ''}`}>{fctValue}</p>
        </div>
        {
          userStatus !== USERSTATUS.attend && <>
            <p className="usdtCount">{`= ${u} USDT`}</p>
            <div className="editContainer">
              <Button fill='none' className={`cancelBtn ${userStatus !== USERSTATUS.edit ? 'noEditCancelBtn' : ''}`} onClick={() => getData()}>Cancel</Button>
              <Button disabled={submit} className={`submitBtn ${userStatus === USERSTATUS.edit ? 'editSubmitBtn' : ''}`} onClick={tagInSubmitButn}>Submit</Button>
            </div>
          </>
        }
        {
          userStatus === USERSTATUS.attend && <div className="congratulateContainer">
            <p className="title">{data.congratulateTitle}</p>
            <p className="content" dangerouslySetInnerHTML={{__html: congratulateContent}}></p>
            <div className="editContainer" onClick={() => setUserStatus(USERSTATUS.edit)}>
              <img src={data.editIcon} alt="" className="editIcon" />
              <p className="editTxt">{data.editWarn}</p>
            </div>
          </div>
        }
      </div>
      <div className="footContainer">
        <p className="applyWarn" onClick={tagInApplyWarn}>{data.applyWarn}</p>
      </div>
      <Mask visible={maskVisible} opacity='0.5' className="maskContainer" afterClose={() => afterCloseMask()} >
        <div className="contentDiv">
          <div className="toolContainer"><img src={data.closeIcon} alt="" className="closeButn" onClick={() => setMaskVisible(false)} /></div>
          {
            process === 0 && <>
              <p className="applyTitle">{data.applyTitle}</p>
              <p className="applyContent" dangerouslySetInnerHTML={{__html: data.applyContent}}></p>
              <input className="maskInputDiv" id="email"  type='text' placeholder='Enter your email' value={emailValue} onChange={(e) => setEmailValue(e.target.value)} />
              <Button disabled={emailConfirm} className="confirmBtn" onClick={() => bandingEmail()}>Confirm</Button>
            </>
          }
          {
            process === 1 && <>
              <p className="applyTitle">{data.paymentTitle}</p>
              <input className="maskInputDiv" id="payment" type='password' placeholder='Enter Payment PIN to verify' value={paymentValue} onChange={(e) => setPaymentValue(e.target.value)} />
              <Button block disabled={paymentConfirm} className="confirmBtn" onClick={() => tagInEnterPaymentConfirm()}>Confirm</Button>
            </>
          }
        </div>
      </Mask>
    </div>
  )
}

export default withRouter(Private2Page)