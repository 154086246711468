import React, { useEffect, useState, useRef } from 'react'
import { withRouter } from "react-router-dom"
import { Toast, PullToRefresh, InfiniteScroll, DotLoading } from 'antd-mobile'
import { Artworks, MarketplaceFoot } from "@component"
import { get } from '@service'
import './styles.less'

const statusRecord = {
  pulling: 'Pull down to refresh',
  canRelease: 'Release to refresh immediately',
  refreshing: 'Loading...',
  complete: 'Refresh successfully',
}

const CollectionPage = ({ history }) => {
  const [data, setData] = useState(null)
  const multiRow = useRef(null)
  const [showMore, setShowMore] = useState(false)
  const [artworksPage, setArtworksPage] = useState(1)
  const [artworksReload, setArtworksReload] = useState(false)
  const [artworkData, setArworkData] = useState(null)
  const [artworkLoading, setArtworkLoading] = useState(false)

  useEffect(() => {
    if (!!data) {
      const height = parseInt(getComputedStyle(multiRow.current).height)
      const _showMore = height > 63 ? true : false
      setShowMore(_showMore)
    }
  }, [data]) 

  useEffect(() => {
    async function getData() {
      if (!!history.location && !!history.location.state) {
        const _state = history.location.state
        try {
          Toast.show({
            icon: 'loading',
            content: 'Loading…',
            duration: 0
          })
          const res = await get(`api/product/collection/get.json?id=${_state.id}`)
          Toast.clear()
          if (res.status === 200) {
            document.body.scrollIntoView()
            setData(res.data.collection)
          } else {
            Toast.show({
              icon: 'fail',
              content: res.statusText,
            })
          }
        } catch (error) {
          Toast.show({
            icon: 'fail',
            content: error.message.toString(),
          })
        }
      }
    }
    getData()
  // eslint-disable-next-line
  }, [history.location.state])

  function onRefreshArtworks() {
    setArtworksPage(1)
    setArtworksReload(!artworksReload)
  }

  function loadMoreArtworks() {
    var _artworksPage = artworksPage + 1
    setArtworksPage(_artworksPage)
  }

  useEffect(() => {
    async function getArtworks() {
      if (!!history.location && !!history.location.state) {
        const _state = history.location.state
        try {
          setArtworkLoading(true)
          const res = await get(`api/product/list.json?product_collection_id=${_state.id}&paged=${artworksPage}&product_type=1&posts_per_page=10&product_source_id=none`)
          setArtworkLoading(false)
          if (res.status === 200) {
            var datas = artworksPage === 1 ? [] : artworkData.products
            res.data.products.forEach(element => {
              datas.push(element)
            })
            res.data.products = datas
            setArworkData(res.data)
          } else {
            Toast.show({
              icon: 'fail',
              content: res.statusText,
            })
          }
        } catch (error) {
          Toast.show({
            icon: 'fail',
            content: error.message.toString(),
          })
        }
      }
    }
    getArtworks()
  // eslint-disable-next-line
  }, [history.location.state, artworksPage, artworksReload])

  return (
    <>
    {
      !!data ? <div className="collection-page">
        <div className="head-div">
          <img src={data.banners.small} alt="" className="image" />
          <div className="head-container">
            <div className="head-icon-number">
              <img src={data.thumbnails.small} alt="" className="icon" />
              <p className="number">{data.count} <span>Items</span></p>
              <p className="number">{data.owner_count} <span>Owners</span></p>
              <p className="number">{`$${data.sold_amount/1000}K`} <span>Sold</span></p>
              <div style={{ width: "16px" }}></div>
            </div>
            <p className="name">{data.name}</p>
          </div>
        </div>
        <p className={`desc ${showMore ? 'desc-after': ''}`} ref={multiRow}>{data.description} 
          <span onClick={() => setShowMore(false)}>Read More</span>
        </p>
        <div className="image-name" onClick={() => history.push({
          pathname: "/creator", 
          state: { 
            id: data.product_creator.id
          }
        })}>
          <img src={data.product_creator?.thumbnails.small} alt="" className="icon" />
          <p className="name">{data.product_creator?.name}</p>
        </div>
        <div className="data-list">
        {
          !!artworkData && <PullToRefresh onRefresh={onRefreshArtworks} renderText={status => {
            return <div>{statusRecord[status]}</div>
          }}>
            <Artworks datas={artworkData.products} push={(id) => history.push({
              pathname: "/artwork", 
              state: { 
                id: id
              }
            })} />
            <InfiniteScroll loadMore={loadMoreArtworks} hasMore={artworkData.total_pages > artworksPage}>
            {
              (artworkData.total_pages > artworksPage || artworkLoading) ? <DotLoading /> : <div></div>
            }
            </InfiniteScroll>
          </PullToRefresh>
        }
        </div>
        <MarketplaceFoot />
      </div> : <div className="collection-page">
      </div>
    }
    </>
  )
}
export default withRouter(CollectionPage)