import React, {
  useState,
  useEffect
} from 'react'
import { Button, Mask, Input } from 'antd-mobile'
import { constant } from "@json"
import './styles.less'

const PaymentMask = (props) => {
  const data = constant.private
  const [visible, setVisible] = useState(false)
  const [paymentValue, setPaymentValue] = useState('')
  const [paymentConfirm, setPaymentConfirm] = useState(true)

  useEffect(() => {
    setVisible(props.visible)
  }, [props.visible])

  useEffect(() => {
    const _paymentConfirm = !!paymentValue ? false : true
    setPaymentConfirm(_paymentConfirm)
  }, [paymentValue])

  return (
    <Mask visible={visible} forceRender={true} opacity='0.5' className="paymentMask" afterClose={() => {
      setPaymentValue("")
      props.afterClose()
    }} >
      <div className="contentDiv">
        <div className="toolContainer"><img src={data.closeIcon} alt="" className="closeButn" onClick={() => setVisible(false)} /></div>
        <p className="applyTitle">{data.paymentTitle}</p>
        <Input className="maskInput" type="tel" style={{"WebkitTextSecurity":"disc"}} placeholder='Enter Payment PIN to verify' value={paymentValue} onChange={value => setPaymentValue(value)} />
        <Button block disabled={paymentConfirm} className="confirmBtn" onClick={() => {
          props.confirm(paymentValue)
        }}>Confirm</Button>
      </div>
    </Mask>
  )
}

export default PaymentMask