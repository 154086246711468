import React from 'react'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  MemoryRouter as Router,
  withRouter
} from 'react-router-dom'
import { TabBar } from 'antd-mobile'
import {
  MessageOutline,
  UnorderedListOutline,
} from 'antd-mobile-icons'
import RekallHome from './rekall-home'
import RekallSearch from './rekall-search'
import RekallProfile from './rekall-profile'
import './styles.less'

const Bottom = () => {
  const history = useHistory()
  const location = useLocation()
  const { pathname } = location

  const setRouteActive = (value) => {
    history.push(value)
  }

  const tabs = [
    {
      key: '/rekall/home',
      icon: <img style={{width: "30px"}} src="https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/globalpartner/LV0.png" alt="" />,
      icon2: <img style={{width: "30px"}} src="https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/globalpartner/LV1.png" alt="" />,
    },
    {
      key: '/rekall/search',
      icon: <UnorderedListOutline />,
      icon2: <img style={{width: "30px"}} src="https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/globalpartner/LV1.png" alt="" />,
    },
    {
      key: '/rekall/profile',
      icon: <MessageOutline />,
      icon2: <img style={{width: "30px"}} src="https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/globalpartner/LV1.png" alt="" />,
    },
  ]

  return (
    <TabBar activeKey={pathname} onChange={value => setRouteActive(value)}>
      {tabs.map(item => (
        
          pathname === item.key ? <TabBar.Item key={item.key} icon={item.icon} title={item.title} /> 
          : <TabBar.Item key={item.key} icon={item.icon2} title={item.title} />
        
        // <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
        // <TabBar.Item key={item.key} icon={`${pathname === item.key ? item.icon : item.icon2}`} title={item.title} />
      ))}
    </TabBar>
  )
}

const RekallPage = ({ history }) => {
  return (
    <Router initialEntries={['/rekall/home']}>
      <div className="rekall-page">
        <Switch>
          <Route exact path='/rekall/home'>
            <RekallHome />
          </Route>
          <Route exact path='/rekall/search'>
            <RekallSearch />
          </Route>
          <Route exact path='/rekall/profile'>
            <RekallProfile />
          </Route>
        </Switch>
        <div className="bottom">
          <Bottom />
        </div>
      </div>
    </Router>
  )
}
export default withRouter(RekallPage)