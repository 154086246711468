import { useEffect } from 'react'

const Main = (props) => {
  useEffect(() => {
    document.body.scrollIntoView()
  }, [props.location.pathname])

  useEffect(() => {
    const pathname = props.location.pathname
    var title
    if (pathname === "/deposit/staking" || pathname === "/deposit/subscribtions") {
      title = "Staking"
    } else if (pathname === "/rewards") {
      title = "Rewards"
    } else {
      title = "FuturesCash"
    }
    document.title = title
  }, [props.location.pathname])
  
  return (
    props.children
  )
}
export default Main