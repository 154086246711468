import React, { useState, useEffect } from 'react'
import { Popup, Toast, Dialog, Image } from 'antd-mobile'
import { withRouter } from "react-router-dom"
import { MarketplaceFoot } from "@component"
import { post, get } from '@service'
import { numFormat } from '@tool'
import './styles.less' 

const ArtworkPage = ({ history }) => {
  const [data, setData] = useState(null)
  const [visible, setVisible] = useState(false)
  const [paymentType, setPaymentType] = useState(0)
  const [ekaData, setEkaData] = useState(null)
  const [usdtData, setUsdtData] = useState(null)

  useEffect(() => {
    async function getData() {
      if (!!history.location && !!history.location.state) {
        const _state = history.location.state
        try {
          Toast.show({
            icon: 'loading',
            content: 'Loading…',
            duration: 0
          })
          const res = await get(`api/product/get.json?id=${_state.id}`)
          Toast.clear()
          if (res.status === 200) {
            document.body.scrollIntoView()
            var product = res.data.product
            getPriceAndFee(product)
          } else {
            Toast.show({
              icon: 'fail',
              content: res.statusText,
            })
          }
        } catch (error) {
          Toast.show({
            icon: 'fail',
            content: error.message.toString(),
          })
        }
      }
    }
    getData()
  // eslint-disable-next-line
  }, [history.location.state])

  async function getPriceAndFee(data) {
    try {
      Toast.show({
        icon: 'loading',
        content: 'Loading…',
        duration: 0
      })
      const res = await post('app/rekall/priceAndFee', {
        "usdtPrice": data.price,
      })
      Toast.clear()
      data.ekaData = res
      setData(data)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error.message.toString(),
      })
    }
  }

  useEffect(() => {
    async function getUserData() {
      try {
        Toast.show({
          icon: 'loading',
          content: 'Loading…',
          duration: 0
        })
        const resp = await post('app/account/infoV2', {})
        Toast.clear()
        resp.accounts.forEach(element => {
          if (element.asset === "USDT") {
            setUsdtData(element)
          }
          if (element.asset === "EKA") {
            setEkaData(element)
          }
        })
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    }
    getUserData()
  }, [])

  function toAuthentication() {
    window.futuresCashToAuthentication.postMessage("message from JS...")
  }

  window['authorizationResult'] = (res) => {
    if (res === "suc") {
      toPay()
    } else {
      setVisible(false)
    }
  }

  async function toPay() {
    try {
      Toast.show({
        icon: 'loading',
        content: 'Loading…',
        duration: 0
      })
      const _asset = paymentType === 0 ? "EKA" : "USDT"
      await post('app/rekall/buyNft', {
        "asset": _asset,
        "productId": data.id
      })
      Toast.clear()
      history.push("result")
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error.message.toString(),
      })
    }
  }

  return (
    <>
    {
      !!data ? <div className="artwork-page">
        <Image fit='cover' src={data.thumbnails.small} alt="" className="image" />
        <p className="name">{data.title}</p>
        <div className="price-days">
          <div className="price-div">
            <p className="txt">Price</p>
            <p className="price">{`$${data.price}`}</p>
          </div>
          <div className="days-div" onClick={() => Dialog.alert({
              bodyClassName: "dialog-body-class",
              content: `The staking reward period is ${data.stake_days} days.`,
              confirmText: <p>OK</p>
            })}>
            <img src="https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/marketplace/day.png" alt="" className="icon" />
            <p className="day">{`${data.stake_days} Days`}</p>
          </div>
        </div>
        <div className="amount-div">
          <div className="amount-container">
            <p className="txt">Available</p><p className="number">{data.stock}</p>
          </div>
          <div className="amount-container">
            <p className="txt">Minted</p><p className="number">{data.sales}</p>
          </div>
          <div className="amount-container">
            <p className="txt">Total</p><p className="number">{data.sales + data.stock}</p>
          </div>
        </div>
        <div className="creator-div">
          <Image fit='cover' src={data.product_creator[0].thumbnails.small} alt="" className="icon" />
          <div className="creator-container" onClick={() => history.push({
            pathname: "/creator",
            state: {
              id: data.product_creator[0].id
            }
          })}>
            <p className="txt">Creator</p>
            <p className="txt-name">{data.product_creator[0].name}</p>
          </div>
        </div>
        <div className="collection-div">
          <p className="txt">Collection</p>
          <div className="head-container">
            <p className="txt-name">{data.product_collection[0]?.name}</p>
            <p className="txt-button" onClick={() => history.push({
              pathname: "/collection", 
              state: { 
                id: data.product_collection[0]?.id
              }
            })}>View All</p>
          </div>
          <ul className="body-container">
          {
            data.related.map((item, index) => {
              return <li key={index} className="bodyItem" onClick={() => history.replace({
                pathname: "/artwork", 
                state: { 
                  id: item.id
                }
              })}>
                <Image fit='cover' src={item.thumbnail} alt="" className="item-image" />
                <p className="item-name">{item.title}</p>
              </li>
            })
          }
          </ul>
        </div>
        <MarketplaceFoot />
        <div style={{height: "64px"}}></div>
        <div className="mint-container">
          {
            data.stock > 0 ? <div className="mint-button" onClick={() => setVisible(true)}>Mint and Stake</div>
            : <div className="mint-button soldout">Sold Out</div> 
          }
        </div>
        <Popup
          visible={visible}
          onMaskClick={() => {
            setVisible(false)
          }}
          bodyClassName="artwork-popup-body-class"
        >
          <img src="https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/marketplace/close.png" alt="" className="close-button" onClick={() => setVisible(false)} /> 
          <p className="title">Select Payment Type</p>
          <div className={`select-div ${paymentType === 0 ? 'selected' : ''}`} onClick={() => setPaymentType(0)}>
            <img src="https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/marketplace/EKA.png" alt="" className="select-icon" />
            <p className={`select-txt ${ekaData?.balance < (data.ekaData?.ekaPrice + data.ekaData?.ekaFee) ? 'insufficient' : ''}`}>{`${numFormat(data.ekaData?.ekaPrice + data.ekaData?.ekaFee)} EKA`}
              <span style={{ marginTop: "12px" }}>{`Price: ${numFormat(data.ekaData?.ekaPrice)} EKA`}</span>
              <span>{`Fee: ${numFormat(data.ekaData?.ekaFee)} EKA`}</span>
            </p>
            <p className="avaible">Available: { numFormat(ekaData?.balance) }
              {
                ekaData?.balance < (data.ekaData?.ekaPrice + data.ekaData?.ekaFee) && <span>Insufficient balance</span>
              }
            </p>
          </div>
          <div className={`select-div ${paymentType === 1 ? 'selected' : ''}`} onClick={() => setPaymentType(1)}>
            <img src="https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/marketplace/USDT.png" alt="" className="select-icon" />
            <p className={`select-txt ${usdtData?.balance < (data.ekaData?.usdtPrice + data.ekaData?.usdtFee) ? 'insufficient' : ''}`}>{`${numFormat(data.ekaData?.usdtPrice + data.ekaData?.usdtFee)} USDT`}
              <span style={{ marginTop: "12px" }}>{`Price: ${numFormat(data.ekaData?.usdtPrice)} USDT`}</span>
              <span>{`Fee: ${numFormat(data.ekaData?.usdtFee)} USDT`}</span>
            </p>
            <p className="avaible">Available: { numFormat(usdtData?.balance) }
              {
                usdtData?.balance < (data.ekaData?.usdtPrice + data.ekaData?.usdtFee) && <span>Insufficient balance</span>
              }
            </p>
          </div>
          {
            ((paymentType === 0 && ekaData?.balance < (data.ekaData?.ekaPrice + data.ekaData?.ekaFee)) || (paymentType === 1 && usdtData?.balance < (data.ekaData?.usdtPrice + data.ekaData?.usdtFee))) ? <div className="confirm-button insufficient-button">Confirm and Pay</div>
            : <div className="confirm-button" onClick={() => toAuthentication()}>Confirm and Pay</div>
          }
        </Popup>
      </div> : <div className="artwork-page">
      </div>
    }
    </>
  )
}
export default withRouter(ArtworkPage)