import React, {useState, useEffect} from 'react'
import { withRouter } from "react-router-dom"
import { Input, Button, Toast, Mask, Popup } from 'antd-mobile'
import { getQueryVariable } from '@tool'
import moment from 'moment'
import { post } from '@service'
import { constant } from "@json"
import './styles.less' 

const InvitationPage = ({ history }) => {
  const data = constant.globalpartner
  const data2 = constant.join
  const [assessmentModal, setAssessmentModal] = useState(false)
  const [refererPopup, setRefererPopup] = useState(false)
  const [menu, setMenu] = useState("All")
  const [list, setList] = useState(null)
  const [hasReferralId, setHasReferralId] = useState(true)
  const [value, setValue] = useState("")
  const [onFocus, setOnFocus] = useState(false)
  const [reload, setReload] = useState(false)

  function invite() {
    window.futuresCashShare.postMessage("message from JS...")
  }

  useEffect(() => {
    async function getListData() {
      try {
        Toast.show({
          icon: 'loading',
          content: 'Loading…',
          duration: 0
        })
        var type = menu.toUpperCase()
        const resp = await post(`/app/gpp/directInvitationUsers?userId=${getQueryVariable("userId")}`, {
          type
        })
        Toast.clear()
        console.log(resp)
        var _data = resp.data
        _data.gradeLV = _data.level === 1 ? data.LV1
                      : _data.level === 2 ? data.LV2
                      : _data.level === 3 ? data.LV3
                      : _data.level === 4 ? data.LV4
                      : _data.level === 5 ? data.LV5
                      : _data.level === 6 ? data.LV6
                      : null
        setList(_data)
        setHasReferralId(resp.hasReferralId)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    }
    getListData()
    // eslint-disable-next-line
  }, [menu, reload])

  async function setReferralId() {
    const referralId = value.trim()
    if (!!referralId) {
      try {
        Toast.show({
          icon: 'loading',
          content: 'Loading…',
          duration: 0
        })
        const resp = await post(`/app/gpp/setReferralId?userId=${getQueryVariable("userId")}`, {
          referralId
        })
        Toast.clear()
        console.log(resp)
        setReload(!reload)
        setRefererPopup(false)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    } else {
      Toast.show({
        icon: 'fail',
        content: "Please input your referral ID",
      })
    }
  }

  return (
    <div className="invitation-page">
      <p className="title">Invitation</p>
      <ul className="menu-list">
      {
        ["All", "Valid", "Invalid"].map((item, index) => {
          return <li className={`menu-item ${menu === item ? 'selected' : ''}`} key={index} onClick={() => setMenu(item)}>{ item }</li>
        })
      }
      </ul>
      <div className="data-head">
        <div className="data-head-name user">User</div>
        <div className="data-head-name">Assessment <img src={data.arrow06} alt="" className="select-icon" onClick={() => setAssessmentModal(true)} /></div>
        <div className="data-head-name">Registration Date</div>
      </div>
      {
        !!list ? <ul className="data-list">
        {
          list.map((item, index) => {
            return <li className="data-item" key={index}>
              <div className="user-container">
                {
                  !!item.gradeLV ? <img src={item.gradeLV} alt="" className="grade-icon" />
                  : <p className="grade-txt">-</p>
                }
                
                <p className="username">{`$${item.referralId}`}</p>
              </div>
              <p className={`assessment ${item.assessments === 0 ? 'no-assessment' : ''}`}>{`${item.assessments}U`}</p>
              <p className="date">{moment(item.registrationTime).format("YYYY-MM-DD")}</p>
            </li>
          })
        }
        </ul> : <div className="no-data">
          <img src={data.noinvite} alt="" className="icon" />
          <p className="desc">Didn't invite any friends yet~</p>
          <Button className="invite-button" onClick={() => invite()}>Invite Friends</Button>
        </div>
      }
      {
        !hasReferralId && <div className="referer-container" onClick={() => setRefererPopup(true)}>
          <p className="text">🤝 Add your Referer now</p>
          <img src={data.arrow03} alt="" className="icon" />
        </div>
      }
      <Mask visible={assessmentModal} forceRender={true} opacity='0.5' className="mask-container" >
        <div className="content-container">
          <p className="text" dangerouslySetInnerHTML={{__html: "Net inflow of your invitees, and the invitees of your invitees. <span class=\"bold\">Assessment = Total Deposit - Total Withdrawal.</span> (If assessment is negative, it will be shown as 0.)"}}></p>
          <Button className="confirm-button" onClick={() => setAssessmentModal(false)}>Confirm</Button>
        </div>
      </Mask>
      <Popup visible={refererPopup} onMaskClick={() => setRefererPopup(false)} className="invitation-popup-container">
        <div className="add-referer-container">
          <div className="container">
            <img src={data2.close} alt="" className="close-button" onClick={() => setRefererPopup(false)} />
            <p className="name">Add My Referer</p>
            <p className="input-name">Referral ID</p>
            <div className={`input-container ${onFocus ? 'input-container-active' : ''}`}>
              <Input clearable value={value} type='text' onFocus={() => setOnFocus(true)} onBlur={() => setOnFocus(false)} onChange={val => { setValue(val) }} className="input-div" />
            </div>
            <Button className="confirm-button" onClick={() => setReferralId()}>Confirm</Button>
          </div>
        </div>
      </Popup>
    </div>
  )
}
export default withRouter(InvitationPage)