// const baseURL = 'http://10.88.10.59:8899/'
const baseURL = "https://api.pifutures.org/"
// const baseURL = "https://test.api.pifutures.org/"

const rekallBaseURL = "https://rekall.net/"
// const rekallBaseURL = "https://test.rekall.net/"

export const BASE_URL = baseURL
export const TIMEOUT = 30000
export const ERROR_OBJECT = { 
  code: null,  // code: 100 => 请求拦截报错。200 => 响应拦截报错。300 => Http报错 400 => 返回数据报错
  message: null, 
  data: null 
}
export const REKALL_BASE_URL = rekallBaseURL