import React, { 
  useState,
  useEffect,
  useRef
} from 'react'
import { withRouter } from "react-router-dom"
import { Button, Popup, Input, Toast } from 'antd-mobile'
import { constant } from "@json"
import { getQueryVariable } from '@tool'
import { post } from '@service'
import { ListHeader, PaymentMask } from "@component"
import './styles.less'

const DepositStakingPage = ({ history }) => {
  const data = constant.deposit
  const [datas, setDatas] = useState(null)
  const [selectIndex, setSelectIndex] = useState(-1)
  const [selectData, setSelectData] = useState(null)
  const [selectItem, setSelectItem] = useState(-1)
  const [popupVisible, setPopupVisible] = useState(false)
  const [stakingAmount, setStakingAmount] = useState("")
  const [paymentVisible, setPaymentVisible] = useState(false)
  const [subscribeDesc, setSubscribeDesc] = useState("")
  const [reload, setReload] = useState(false)
  const stakingAmountRef = useRef()

  useEffect(() => {
    async function getConfig() {
      try {
        Toast.show({
          icon: 'loading',
          content: 'Loading…',
          duration: 0
        })
        const resp = await post(`/staking/getConfig?userId=${getQueryVariable("userId")}`)
        Toast.clear()
        resp.forEach(element => {
          element.dayInterest = 0
          element.showList = false
          element.dayInterestStr = '0.12%'
          element.list.forEach(data => {
            element.dayInterestStr = data.dayInterest > element.dayInterest ? data.dayInterestStr : element.dayInterestStr;
            element.dayInterest = data.dayInterest > element.dayInterest ? data.dayInterest : element.dayInterest;
          })
        })
        setDatas(resp)  
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    }
    if (!!getQueryVariable("userId")) {
      getConfig() 
    }
  }, [reload])

  useEffect(() => {
    if (!!selectData) {
      const count = (!stakingAmount || parseFloat(stakingAmount) < selectItem.startNumber) ? "-" : parseFloat((parseFloat(stakingAmount) * selectItem.dayInterest).toFixed(6))
      var _subscribeDesc = ""
      if (selectItem.assetType === "FCT") {
        _subscribeDesc = `You will receive a daily interest of <br /><span class="lightText">${count} ${selectItem.assetType}</span> per day (in ${selectItem.day}days)`
      } else {
        _subscribeDesc = `You will receive daily interest in <span class="lightText">FCT,<br /> equivalent to ${count} ${selectItem.assetType}</span> per day (in ${selectItem.day}days)`
      }
      setSubscribeDesc(_subscribeDesc)
    }
  }, [selectData, selectItem, stakingAmount])

  async function deposit(value) {
    try {
      Toast.show({
        icon: 'loading',
        content: 'Loading…',
        duration: 0
      })
      await post(`/staking/deposit?userId=${getQueryVariable("userId")}`,{
        "stakingConfigType": selectItem.stakingConfigType,
        "amount": stakingAmount,
        "paymentPassword": value
      })
      Toast.clear()
      Toast.show({
        icon: 'success',
        content: "Success"
      })
      setPaymentVisible(false)
      setPopupVisible(false)
      setTimeout(() => {
        setReload(!reload)
      }, 3000)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error.message.toString(),
      })
    }
  }

  return (
    <div className="depositStakingPage">
      <ListHeader root={true} from="deposit" toPush={() => { history.push(`/deposit/subscribtions?userId=${getQueryVariable("userId")}`) }} />
      <div style={{width:"100%", textAlign:"center", fontSize: "18px", lineHeight: "26px", fontWeight: "600", color: "@color_1b1b1b", margin: "80px 0"}}>New Staking Program Coming Soon</div>
      { !!datas && <p className="sectionTitle" style={{ display: "none" }}>Staking Program</p> }
      <ul className="depositList" style={{ display: "none" }}>
      {
        !!datas && datas.map((item, index) => {
          return <li className="dataItem" key={index}>
            <div className="headContainer" onClick={() => {
              setSelectIndex(selectIndex === index ? -1 : index)
            }}>
            <img src={item.icon} alt="" className="icon" />
            <div className="nameContainer">
              <p className={`title ${item.name === "FCT" ? 'fire' : ''}`}>{item.name}</p>
              <p className="available">{`Available: ${item.available}`}</p>
            </div>
            <div className={`dailyInterestContainer ${index === selectIndex ? 'none' : 'block'}`}>
               {/* <p className="dayInterest">{`${item.dayInterestStr}`}</p> */}
               <p className="dayInterest">{`${parseFloat(parseFloat(item.dayInterest * 36000).toFixed(6))}%`}</p>
               {/* <p className="dayInterestDesc">Daily interest</p> */}
               <p className="dayInterestDesc">APY up to</p>
             </div>
             <img src={data.direction} alt="" className={`direction ${selectIndex === index ? 'upDirectionAnimation' : selectIndex !== -1 ? 'downDirectionAnimation' : ''}`} />
           </div>
           <ul className={`bodyList ${selectIndex === index ? 'showBodyListAnimation' : 'hideBodyListAnimation'}`} >
           {
              item.list.map((type, i) => {
                return <li className="itemContainer" key={i} id="subscribeId">
                <div className="content">
                   {/* <p className="interest">{`${type.dayInterestStr}`}</p> */}
                   <p className="interest">{`${parseFloat(parseFloat(type.dayInterest * 36000).toFixed(6))}%`}</p>
                   <p className="duration">{`${type.day} days | Low risk`}</p>
                </div>
                <Button className="subscribeBtn" onClick={() => {
                  setSelectData(item)
                  setSelectItem(type)
                  setPopupVisible(true)
                  setTimeout(function(){
                    stakingAmountRef.current.focus()
                  }, 500)
                }}>Subscribe</Button>
                </li>
              })
            }
           </ul>
         </li>
        })
      }
      </ul>


      <Popup visible={popupVisible} forceRender={true} getContainer={document.getElementById("subscribeId")} afterClose={() => setStakingAmount("")} bodyClassName="subcribeBodyContainer" className="subscribePopup">
      {
        !!selectData && <div className="container">
          <div className="iconAndName">
            <img src={selectData.icon} alt="" className="icon" />
            <p className="name">{selectData.name}</p>
            <img src={data.closeBlack} alt="" className="close" onClick={() => setPopupVisible(false)} />
          </div>
          <ul className="interestAndPeroid">
            <li className="interestAndPeroidContainer">
              <p className="key">Daily interest</p>
              <p className="value">{`${selectItem.dayInterestStr}`}</p>
            </li>
            <li className="interestAndPeroidContainer">
              <p className="key">Peroid</p>
              <p className="value">{`${selectItem.day} days`}</p>
            </li>
          </ul>
          <div className="line"></div>
          <div className="stakingTitle">
            <p className="name">Staking amount</p>
            <p className="available">{selectData.available}</p>
          </div>
          <div className="inputContainer">
            <Input className="stakingInput" id="stakingInput" ref={stakingAmountRef} placeholder={`Min:${selectItem.startNumber} ${selectData.name}`} type="number" value={stakingAmount} onChange={(amount) => {
              const _amount = amount ? amount.toString().match(/^\d+(?:\.\d{0,6})?/) : amount
              setStakingAmount(parseFloat(_amount))
            }} />
            <p className="maxButn" onClick={() => setStakingAmount(selectData.available)}>Max</p>
          </div>
          <p className="desc" dangerouslySetInnerHTML={{__html: subscribeDesc}}></p>
          <Button className="subscribeButn" disabled={stakingAmount.length === 0 || parseFloat(stakingAmount) < selectItem.startNumber} onClick={() => setPaymentVisible(true)}>Subscribe</Button>
        </div>
      }
      </Popup>
      <PaymentMask visible={paymentVisible} 
        afterClose={() => { setPaymentVisible(false) }}
        confirm={(value) => deposit(value)} />
    </div>
  )
}
export default withRouter(DepositStakingPage)
