import React from 'react'
import { withRouter } from "react-router-dom"
import { constant } from "@json"
import './styles.less'

const RulePage = () => {
  const data = constant.rule

  return (
    <div className="rulePage">
      <p className="title">{data.title}</p>
      <div className="line"></div>
      <ul className="contentList">
      {
        data.contents.map((item, index) => {
          return <li className="contentItem" key={index}>
            <div className="tag"></div>
            <p className="content" dangerouslySetInnerHTML={{__html: item}}></p>
          </li>
        })
      }
      </ul>
    </div>
  )
}
export default withRouter(RulePage)