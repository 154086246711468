import React, {useState, useEffect, useRef} from 'react'
import { withRouter } from "react-router-dom"
import { Input, Button, Toast } from 'antd-mobile'
import { constant } from "@json"
import { post } from '@service'
import { useTranslation } from "react-i18next"
import './styles.less' 

const VerifyAccountPage = ({ history }) => {
  const data = constant.globalpartner
  const [userData, setUserData] = useState(null)
  const [value, setValue] = useState("")
  const [timeOut, setTimeOut] = useState(0)
  const [geetestData, setGeetestData] = useState(null)
  const timerID = useRef()
  const [onFocus, setOnFocus] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (!!history.location && !!history.location.state) {
      const _userData = history.location.state
      console.log("_userData:", _userData)
      setUserData(_userData)
      setGeetestData(_userData.geetestData)
      sendVerifyCode(_userData, _userData.geetestData)
    }
  // eslint-disable-next-line
  }, [])

  async function isNeedCaptcha() {
    try {
      Toast.show({
        icon: 'loading',
        content:  t("Loading…"),
        duration: 0
      })
      const data = userData.type === "phone" ? {
        "mobile": userData.account,
        "countryCode": userData.countryCode,
        "email": "",
        "username": ""
      } : {
        "mobile": "",
        "countryCode": "",
        "email": userData.account,
        "username": ""
      }
      const resp = await post("/app/captcha/needCaptcha", data)
      Toast.clear()
      if (resp.need) {
        if (resp.type === "TENCENT") {
          initTencentCaptcha().show() 
        } else {
          window.grecaptcha.ready(function() {
            window.grecaptcha.execute("6LdldPwZAAAAACfK_nyA9IJ42FBtiNojpqURLQl4", {action: "submit"}).then(function(token) {
              setGeetestData(token)
              sendVerifyCode(userData, token)
            })
          })
        }
      } else {
        sendVerifyCode(userData, userData.geetestData)
      }
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error.message.toString(),
      })
    }
  }

  function initTencentCaptcha() {
    return new window.TencentCaptcha("2038742797", (res) => {
      setGeetestData(res)
      sendVerifyCode(userData, res)
    }, {})
  }

  async function sendVerifyCode(userData, result) {
    if (userData.type === "phone") {
      try {
        Toast.show({
          icon: 'loading',
          content:  t("Loading…"),
          duration: 0
        })
        const data = userData.captchaType === "TENCENT" ? {
          "captchaType": "TENCENT",
          "countryCode": userData.countryCode,
          "tencentRandstr": result.randstr,
          "tencentTicket": result.ticket,
          "mobile": userData.account,
          "verify_code_tag": "SIGNUP",
          "userId": ""
        } : {
          "captchaType": "RECAPTCHA",
          "countryCode": userData.countryCode,
          "googleResponse": result,
          "mobile": userData.account,
          "verify_code_tag": "SIGNUP",
          "userId": ""
        }
        await post("/app/account/sendVerifyCodeV6", data)
        Toast.clear()
        Toast.show({
          icon: 'success',
          content: t("Success")
        })
        countDown()
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    } else {
      try {
        Toast.show({
          icon: 'loading',
          content: t("Loading…"),
          duration: 0
        })
        const data = userData.captchaType === "TENCENT" ? {
          "captchaType": "TENCENT",
          "email": userData.account,
          "tencentRandstr": result.randstr,
          "tencentTicket": result.ticket,
          "tag": "SIGNUP"
        } : {
          "captchaType": "RECAPTCHA",
          "email": userData.account,
          "googleResponse": result,
          "tag": "SIGNUP"
        }
        await post("/app/account/sendEmailVerifyCodeV4", data)
        Toast.clear()
        Toast.show({
          icon: 'success',
          content: t("Success")
        })
        countDown()
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    }
  }

  function countDown() {
    if (!!timerID.current) {
      clearInterval(timerID.current)
    }
    let timeOut = 60
    setTimeOut(timeOut)
    timerID.current = setInterval(() => {
      if (timeOut > 0) {
        timeOut = timeOut - 1
        setTimeOut(timeOut)
      } else {
        clearInterval(timerID.current)
      }
    }, 1000)
  }

  async function next() {
    if (userData.type === "phone") {
      try {
        Toast.show({
          icon: 'loading',
          content: t("Loading…"),
          duration: 0
        })
        await post("/app/account/checkVerifyCode", {
          "verify_code": value,
          "country_code": userData.countryCode,
          "mobile": userData.account,
          "verify_code_tag": "SIGNUP"
        })
        Toast.clear()
        pushTo()
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    } else {
      try {
        Toast.show({
          icon: 'loading',
          content: t("Loading…"),
          duration: 0
        })
        await post("/app/account/emailVerify", {
          "code": value,
          "email": userData.account,
          "tag": "SIGNUP"
        })
        Toast.clear()
        pushTo()
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    }
  }

  function pushTo() {
    clearInterval(timerID.current)
    history.push({
      pathname: "/setpassword", 
      state: { 
        userData: userData,
        geetestData: geetestData,
        verifyCode: value
      }
    })
  }
  
  return (
    <>
    {
      !!userData ? <div className="verify-account-page">
      <div className="nav-bar" >
        <img src={data.arrow02} alt="" className="back-button" onClick={() => history.goBack()} />
      </div>
      {/* <p className="title">{`Verify Your ${userData.type[0].toUpperCase()}${userData.type.substr(1)}`}</p> */}
      <p className="title">{`${t("Verify Your")} ${userData.type === "phone" ? t("Phone") : t("Email")}`}</p>
      <p className="content">{`${t("A verification code will be sent to")} ${userData.account}`}</p>
      <div className={`input-container ${onFocus ? 'input-container-active' : ''}`}>
        <Input clearable value={value} onFocus={() => setOnFocus(true)} onBlur={() => setOnFocus(false)} onChange={val => { setValue(val) }} className="input-div" />
        {
          timeOut === 0 ? <p className="resend-button" onClick={() => isNeedCaptcha()}>{ t("Resend") }</p> : <p className="resend-button resend-button-disable">{`${t("Resend")} ${timeOut}s`}</p>
        }
      </div>
      {
        !!value.trim() ? <Button className="send-button" onClick={() => next()}>{ t("Next") }</Button>
        : <Button className="send-button send-button-disable">{ t("Next") }</Button>
      }
    </div> : <></>
    }
    </>
  )
}
export default withRouter(VerifyAccountPage)
