import React, {useState, useEffect} from 'react'
import { withRouter } from "react-router-dom"
import { Input, Button, Toast } from 'antd-mobile'
import { constant } from "@json"
import { post } from '@service'
import { useTranslation } from "react-i18next"
import './styles.less' 

const SetPasswordPage = ({ history }) => {
  const data = constant.globalpartner
  const [value, setValue] = useState("")
  const [confirmValue, setConfirmValue] = useState("")
  const [registerData, setRegisterData] = useState(null)
  const [onFocus, setOnFocus] = useState(false)
  const [onFocusConfirm, setOnFocusConfirm] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (!!history.location && !!history.location.state) {
      const _data = history.location.state
      console.log(_data)
      setRegisterData(_data)
    }
  // eslint-disable-next-line
  }, [])

  function next() {
    const _value = value.trim()
    const _confirmValue = confirmValue.trim()
    var gz = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]*$/
    if (!_value) {
      Toast.show({
        icon: 'fail',
        content: t("Please input your password"),
      })
    } else if (!_confirmValue) {
      Toast.show({
        icon: 'fail',
        content: t("Please confirm your password"),
      })
    } else if (_value.length < 8) {
      Toast.show({
        icon: 'fail',
        content: t("At least 8 characters"),
      })
    } else if (!gz.test(value)) {
      Toast.show({
        icon: 'fail',
        content: t("A mixture of letters and numbers"),
      })
    } else if (_value !== _confirmValue) {
      Toast.show({
        icon: 'fail',
        content: t("Two passwords need to be consistent"),
      })
    } else {
      // needGeetest()
      isNeedCaptcha()
    }
  }

  async function isNeedCaptcha() {
    try {
      Toast.show({
        icon: 'loading',
        content: t("Loading…"),
        duration: 0
      })
      const data = registerData.userData.type === "phone" ? {
        "mobile": registerData.userData.account,
        "countryCode": registerData.userData.countryCode,
        "email": "",
        "username": ""
      } : {
        "mobile": "",
        "countryCode": "",
        "email": registerData.userData.account,
        "username": ""
      }
      const resp = await post("/app/captcha/needCaptcha", data)
      Toast.clear()
      if (resp.need) {
        // initGeetest()
        if (resp.type === "TENCENT") {
          initTencentCaptcha().show()
        } else {
          window.grecaptcha.ready(function() {
            window.grecaptcha.execute("6LdldPwZAAAAACfK_nyA9IJ42FBtiNojpqURLQl4", {action: "submit"}).then(function(token) {
              var _registerData = registerData
              _registerData.geetestData = token
              setRegisterData(_registerData)
              register(_registerData)
            })
          })
        }
      } else {
        register(registerData)
      }
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error.message.toString(),
      })
    }
  } 

  function initTencentCaptcha() {
    return new window.TencentCaptcha("2038742797", (res) => {
      var _registerData = registerData
      _registerData.geetestData = res
      setRegisterData(_registerData)
      register(_registerData)
    }, {})
  }

  async function register(__registerData) {
    if (registerData.userData.type === "phone") {
      try {
        Toast.show({
          icon: 'loading',
          content: t("Loading…"),
          duration: 0
        })
        const data = __registerData.userData.captchaType === "TENCENT" ? {
          "captchaType": "TENCENT",
          "country_code": __registerData.userData.countryCode,
          "tencentRandstr": __registerData.geetestData.randstr,
          "tencentTicket": __registerData.geetestData.ticket,
          "alpha_code":  __registerData.userData.alphaCode,
          "country_name": __registerData.userData.countryName,
          "mobile": __registerData.userData.account,
          "verify_code": __registerData.verifyCode,
          "password": value,
          "invite_code": __registerData.userData.referralCode,
          "verify_code_tag": "SIGNUP"
        } : {
          "captchaType": "RECAPTCHA",
          "country_code": __registerData.userData.countryCode,
          "googleResponse": __registerData.geetestData,
          "alpha_code":  __registerData.userData.alphaCode,
          "country_name": __registerData.userData.countryName,
          "mobile": __registerData.userData.account,
          "verify_code": __registerData.verifyCode,
          "password": value,
          "invite_code": __registerData.userData.referralCode,
          "verify_code_tag": "SIGNUP"
        }
        const resp = await post("/app/account/signUpV6", data)
        Toast.clear()
        Toast.show({
          icon: 'success',
          content: t("Success")
        })
        pushTo(resp.token)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    } else {
      try {
        Toast.show({
          icon: 'loading',
          content: t("Loading…"),
          duration: 0
        })
        const data = __registerData.userData.captchaType === "TENCENT" ? {
          "captchaType": "TENCENT",
          "email": __registerData.userData.account,
          "tencentRandstr": __registerData.geetestData.randstr,
          "tencentTicket": __registerData.geetestData.ticket,
          "verify_code": __registerData.verifyCode,
          "password": value,
          "invite_code": __registerData.userData.referralCode,
          "verify_code_tag": "SIGNUP"
        } : {
          "captchaType": "RECAPTCHA",
          "email": __registerData.userData.account,
          "googleResponse": __registerData.geetestData,
          "verify_code": __registerData.verifyCode,
          "password": value,
          "invite_code": __registerData.userData.referralCode,
          "verify_code_tag": "SIGNUP"
        }
        const resp = await post("/app/account/signUp/email/v2", data)
        Toast.clear()
        Toast.show({
          icon: 'success',
          content: t("Success")
        })
        pushTo(resp.token)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    }
  }

  function pushTo(tokenString) {
    localStorage.setItem("authorization-session", tokenString)
    history.push("setusername")
  }
  
  return (
    <div className="set-password-page">
      <div className="nav-bar" >
        <img src={data.arrow02} alt="" className="back-button" onClick={() => history.goBack()} />
      </div>
      <p className="title">{ t("Set Your Password") }</p>
      <p className="content">{ t("At least 8 characters - A mixture of letters and numbers.") }</p>
      <p className="input-name">{ t("Password") }</p>
      <div className={`input-container ${onFocus ? 'input-container-active' : ''}`}>
        <Input clearable value={value} type='password' onFocus={() => setOnFocus(true)} onBlur={() => setOnFocus(false)} onChange={val => { setValue(val) }} className="input-div" />
      </div>
      <p className="input-name">{ t("Confirm Your Password") }</p>
      <div className={`input-container ${onFocusConfirm ? 'input-container-active' : ''}`}>
        <Input clearable value={confirmValue} type='password' onFocus={() => setOnFocusConfirm(true)} onBlur={() => setOnFocusConfirm(false)} onChange={val => { setConfirmValue(val) }} className="input-div" />
      </div>
      <Button className="next-button" onClick={() => next()}>{ t("Next") }</Button>
    </div>
  )
}
export default withRouter(SetPasswordPage)
