/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { Toast, SwipeAction } from "antd-mobile"
import { getQueryVariable } from "@tool"
import PullToRefresh from "react-pullrefresh"
import { post } from "@service"
import "./styles.less" 

const fc_icon = "https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/explore/fc_icon.png"
const sticky_icon = "https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/explore/sticky_icon.png"

const IndexPage = ({ history }) => {
  const token = getQueryVariable("token")
  const [banner, setBanner] = useState(null)
  const [apps, setApps] = useState([])
  const [mineUrl, setMineUrl] = useState("")

  useEffect(() => {
    if (window.flutterSetNavBarStyle) {
      // none, normal, applet(小程序),
      window.flutterSetNavBarStyle.postMessage("none") 
    }
  }, [])

  async function getBanner() {
    try {
      Toast.show({
        icon: 'loading',
        content: 'Loading…',
        duration: 0
      })
      const resp = await post("app/explore/banner")
      Toast.clear()
      setBanner(resp)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error.message.toString(),
      })
    }
  }

  async function getApps() {
    try {
      Toast.show({
        icon: 'loading',
        content: 'Loading…',
        duration: 0
      })
      const resp = await post("app/explore/apps")
      Toast.clear()
      setApps(resp)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error.message.toString(),
      })
    }
  }

  async function getMineUrl() {
    try {
      const resp = await post("app/account/getFuturesChainToken")
      setMineUrl(resp)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error.message.toString(),
      })
    }
  }

  const onRefresh = () => {
    if (!!token) {
      getBanner()
      getApps()
      getMineUrl()
    }
  }

  useEffect(() => {
    !!token && localStorage.setItem("authorization-session", token)
    onRefresh()
  }, [token])

  const setPin = async (appId, isPin) => {
    try {
      Toast.show({
        icon: 'loading',
        content: 'Loading…',
        duration: 0
      })
      await post("app/explore/set_pin", {
        appId, isPin
      })
      Toast.clear()
      getApps()
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error.message.toString(),
      })
    }
  }

  const onclickEvent = (data) => {
    if (!data.url || !data.canJump) {
      return
    } else if (data.url.startsWith("http")) {
      if (!data.isOuterWebView && !!window.gotoWeb) {
        const url = data.type === "MINGING" ? mineUrl : data.url
        window.gotoWeb.postMessage(url)
      } else if (!!data.isOuterWebView && !!window.flutterOpenUrl) {
        window.flutterOpenUrl.postMessage(banner.url)
      }
    } else if (data.url.startsWith("futurescash://") && !!window.jumpTo) {
      const url = `/${data.url.replace("futurescash://", "")}`
      window.jumpTo.postMessage(url)
    }
  }

  return (
    <div className="explore-page">
      <PullToRefresh color={"#ff6c03"} onRefresh={onRefresh} className="refreshHead">
        <p className="title">Explore</p>
        {
          !!banner && <img src={banner.imgUrl} alt="" className="banner" onClick={() => onclickEvent(banner)} />
        }
        {
          !!apps && apps.length > 0 && <p className="session-title">Applications</p>
        }
        {
          !!apps && apps.length > 0 && <div className="session-list">
          {
            apps.map((item, index) => {
              return <SwipeAction key={index} rightActions={[{
                key: "pin",
                text: <p className="item-pin">{ !!item.isPinned ? "Unpin" : "Pin" }</p>,
                color: "#FF7700",
                onClick: () => setPin(item.appId, !item.isPinned)
              }]}>
                <div className="session-item" onClick={() => onclickEvent(item)}>
                  <img src={item.iconImageUrl} alt="" className="item-icon" />
                  <div className="item-content">
                    <div className="item-name-container">
                      <p className="item-name">{ item.title }</p>
                      {
                        !!item.fcWalletFunction && <img src={fc_icon} alt="" className="item-icon" />
                      }
                    </div>
                    <p className="item-desc">{ item.desc }</p>
                  </div>
                  {
                    !!item.isPinned && <img src={sticky_icon} alt="" className="item-sticky" />
                  }
                </div>
              </SwipeAction>
            })
          }
          </div>
        }
      </PullToRefresh>
    </div>
  )
}
export default withRouter(IndexPage)