import React, {useState, useEffect} from 'react'
import { withRouter } from "react-router-dom"
import { Tabs, PullToRefresh, Toast, InfiniteScroll, DotLoading } from 'antd-mobile'
import { Artworks, Collections, MarketplaceFoot } from "@component"
import { get } from '@service'
import './styles.less'

const statusRecord = {
  pulling: 'Pull down to refresh',
  canRelease: 'Release to refresh immediately',
  refreshing: 'Loading...',
  complete: 'Refresh successfully',
}

const CreatorPage = ({ history }) => {
  const [data, setData] = useState(null)
  const [artworksPage, setArtworksPage] = useState(1)
  const [artworksReload, setArtworksReload] = useState(false)
  const [artworkData, setArworkData] = useState(null)
  const [artworkLoading, setArtworkLoading] = useState(false)
  const [collectionData, setCollectionData] = useState(null)
  const [collectionReload, setCollectionReload] = useState(false)

  useEffect(() => {
    async function getData() {
      if (!!history.location && !!history.location.state) {
        const _state = history.location.state
        try {
          Toast.show({
            icon: 'loading',
            content: 'Loading…',
            duration: 0
          })
          const res = await get(`api/product/creator/get.json?id=${_state.id}`)
          Toast.clear()
          if (res.status === 200) {
            document.body.scrollIntoView()
            setData(res.data.creator)
          } else {
            Toast.show({
              icon: 'fail',
              content: res.statusText,
            })
          }
        } catch (error) {
          Toast.show({
            icon: 'fail',
            content: error.message.toString(),
          })
        }
      }
    }
    getData()
  // eslint-disable-next-line
  }, [history.location.state])

  function onRefreshArtworks() {
    setArtworksPage(1)
    setArtworksReload(!artworksReload)
  }

  function loadMoreArtworks() {
    var _artworksPage = artworksPage + 1
    setArtworksPage(_artworksPage)
  }

  useEffect(() => {
    async function getArtworks() {
      if (!!history.location && !!history.location.state) {
        const _state = history.location.state
        try {
          setArtworkLoading(true)
          const res = await get(`api/product/list.json?product_creator_id=${_state.id}&paged=${artworksPage}&product_type=1&posts_per_page=10&product_source_id=none`)
          setArtworkLoading(false)
          if (res.status === 200) {
            var datas = artworksPage === 1 ? [] : artworkData.products
            res.data.products.forEach(element => {
              datas.push(element)
            })
            res.data.products = datas
            setArworkData(res.data)
          } else {
            Toast.show({
              icon: 'fail',
              content: res.statusText,
            })
          }
        } catch (error) {
          Toast.show({
            icon: 'fail',
            content: error.message.toString(),
          })
        }
      }
    }
    getArtworks()
  // eslint-disable-next-line
  }, [history.location.state, artworksPage, artworksReload])

  useEffect(() => {
    async function getCollections() {
      if (!!history.location && !!history.location.state) {
        const _state = history.location.state
        try {
          const res = await get(`api/product/collection/list.json?product_creator_id=${_state.id}&product_source_id=none`)
          if (res.status === 200) {
            setCollectionData(res.data)
          } else {
            Toast.show({
              icon: 'fail',
              content: res.statusText,
            })
          }
        } catch (error) {
          Toast.show({
            icon: 'fail',
            content: error.message.toString(),
          })
        }
      }
    }
    getCollections()
  // eslint-disable-next-line
  }, [history.location.state, collectionReload])

  return (
    <>
    {
      !!data ? <div className="creator-page">
        <div className="image-name">
          <img src={data.thumbnails.small} alt="" className="icon" />
          <p className="name">{data.name}</p>
        </div>
        <Tabs className="tabs">
          <Tabs.Tab title='Artworks' key='artworks' className="tab">
          {
            !!artworkData && <PullToRefresh onRefresh={onRefreshArtworks} renderText={status => {
              return <div>{statusRecord[status]}</div>
            }}>
              <Artworks datas={artworkData.products} push={(id) => history.push({
                pathname: "/artwork", 
                state: { 
                  id: id
                }
              })} />
              <InfiniteScroll loadMore={loadMoreArtworks} hasMore={artworkData.total_pages > artworksPage}>
              {
                (artworkData.total_pages > artworksPage || artworkLoading) ? <DotLoading /> : <div></div>
              }
              </InfiniteScroll>
            </PullToRefresh>
          }
          </Tabs.Tab>
          <Tabs.Tab title='Collections' key='collections' className="tab">
            {
              !!collectionData && <PullToRefresh onRefresh={() => setCollectionReload(!collectionReload)} renderText={status => {
                return <div>{statusRecord[status]}</div>
              }}>
                <Collections datas={collectionData.collections} push={(id) => history.push({
                  pathname: "/collection", 
                  state: { 
                    id: id
                  }
                })} />
              </PullToRefresh>
            }
          </Tabs.Tab>
        </Tabs>
        <MarketplaceFoot />
      </div> : <div className="creator-page">
      </div>
    }
    </>
  )
}
export default withRouter(CreatorPage)