import React, {useEffect, useState} from 'react'
import {withRouter} from "react-router-dom"
import {Button, Toast} from 'antd-mobile'
import {getQueryVariable} from '@tool'
import {constant} from "@json"
import './styles.less'
import moment from "moment";
import { post } from '@service'

const BeGlobalPartnerPage = ({history}) => {
    const data = constant.prenium

    const [datas, setDatas] = useState(null)

    useEffect(() => {
        async function getStatus() {
            try {
                Toast.show({
                    icon: 'loading',
                    content: 'Loading…',
                    duration: 0
                })
                const resp = await post(`/saving/status?userId=${getQueryVariable("userId")}`)
                Toast.clear()
                console.log(resp)
                setDatas(resp)
            } catch (error) {
                Toast.show({
                    icon: 'fail',
                    content: error.message.toString(),
                })
            }
        }
        if (!!getQueryVariable("userId")) {
            getStatus()
        }
    }, [])




    function invite() {
        history.push(`/savings/staking?userId=${getQueryVariable("userId")}`)
    }

    return (
        <div className="be-global-partner-page" >
            <img src={data.futuresCashPremium} alt="" className="bg-image" style={{ marginTop: "0px" }}/>
            {/*<p className="rules-button" onClick={() => window.open("https://support.futures.cash/hc/en-us/articles/7671425429273-Global-PartnerShip-Program")}>Rules</p>*/}


            {
                !!datas &&  datas.premium?
                    <div className=" button-container column active" style={{height:"66px", justifyContent:"center", display:"flex"}}>
                        <div className="activeRow">
                            <img src={data.dagou} alt="" className="iconDagou"/>
                            <div className="activeText">FuturesCash Premium is active</div>
                        </div>
                        <div >
                            <div className="activeSubText">Expiry date is {moment(datas.expiredTime).format("YYYY-MM-DD")}</div>
                        </div>
                    </div>

                    :<div className="button-container column">
                    <div className="activiteTips" style={{textAlign:"center"}}>Users who participate in <span className="activiteSubTips">FCT Staking Program</span> will be automatically upgraded to our Premium members.</div>
                        <Button className="button" onClick={() => invite()}>Subscribe Now</Button>
                        <div className="buttonBottom"></div>
                    </div>
            }

        </div>
    )
}
export default withRouter(BeGlobalPartnerPage)