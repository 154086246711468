const gold = "https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/task/gold.png"
const checked = "https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/task/checked.png"
const checked2 = "https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/task/checked-2.png"
const star1 = "https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/task/star-1.png"
const star2 = "https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/task/star-2.png"
const lock = "https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/task/lock.png"
const arrowLeftWhite = "https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/task/arrow-left-white.png"
const arrowRightWhite = "https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/task/arrow-right-white.png"
const gradient = "https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/task/gradient.png"

const IMAGE = {
  gold, checked, checked2, star1, star2, lock, arrowLeftWhite, arrowRightWhite, gradient
}

export default IMAGE