import React, {
    useState,
    useEffect, useRef
} from 'react'
import {withRouter} from "react-router-dom"
import PullToRefresh from 'react-pullrefresh'
import {ActionSheet, Popup, Button, Toast, Input} from 'antd-mobile'
import moment from 'moment'
import {constant} from "@json"
import {post} from '@service'
import {getAaaFormat, getQueryVariable} from '@tool'
import {ListHeader, PaymentMask} from "@component"
import './styles.less'

const SavingsSubcribtionsPage = ({history}) => {
    const data = constant.deposit
    const [listData, setListData] = useState(null)
    const [savingsData, setSavingsData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [statusSheetVisible, setStatusSheetVisible] = useState(false)
    const [screen, setScreen] = useState("All")
    const [redeemSheetVisible, setRedeemSheetVisible] = useState(false)
    const [savingsRedeemSheetVisible, setSavingsRedeemSheetVisible] = useState(false)
    const [popupVisible, setPopupVisible] = useState(false)
    const [savingsPopupVisible, setSavingsPopupVisible] = useState(false)
    const [paymentVisible, setPaymentVisible] = useState(false)
    const [savingsPaymentVisible, setSavingsPaymentVisible] = useState(false)
    const [page, setPage] = useState(1)
    const [selectItem, setSelectItem] = useState(null)
    const [reload, setReload] = useState(false)
    const stakingAmountRef = useRef()
    const [redeemAmount, setRedeemAmount] = useState("")
    const [redeemAmountError, setRedeemAmountError] = useState(false)


    function onRefresh() {
        setPage(1)
        setReload(!reload)
    }

    function loadMore() {
        var _page = page + 1
        setPage(_page)
    }

    useEffect(() => {
        async function getData() {
            const param = screen === "All" ? {
                "page": page, "pageSize": 10
            } : {
                "page": page, "pageSize": 10, "status": screen.toUpperCase()
            }
            try {
                Toast.show({
                    icon: 'loading',
                    content: 'Loading…',
                    duration: 0
                })
                setLoading(true)
                var resp = await post(`/staking/depositHistory?userId=${getQueryVariable("userId")}`, param)
                setLoading(false)

                var datas = page === 1 ? [] : listData.content
                resp.content.forEach(element => {
                    datas.push(element)
                })
                resp.content = datas
                setListData(resp)
                setLoading(true)
                var savingsResp = await post(`/saving/order?userId=${getQueryVariable("userId")}`, {"token": "FCT"})
                setLoading(false)
                console.log(savingsResp)
                if (savingsResp.length > 0) {
                    setSavingsData(savingsResp[0])
                }
                Toast.clear()
            } catch (error) {
                Toast.show({
                    icon: 'fail',
                    content: error.message.toString(),
                })
            }
        }

        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, screen, reload])


    async function redeem(value) {
        try {
            Toast.show({
                icon: 'loading',
                content: 'Loading…',
                duration: 0
            })
            await post(`/staking/redeem?userId=${getQueryVariable("userId")}`, {
                "stakingId": selectItem.id,
                "paymentPassword": value
            })
            Toast.clear()
            Toast.show({
                icon: 'success',
                content: "Success",
            })
            setPaymentVisible(false)
            setPopupVisible(false)
            setTimeout(() => {
                setReload(!reload)
            }, 3000)
        } catch (error) {
            Toast.show({
                icon: 'fail',
                content: error.message.toString(),
            })
        }
    }

    async function redeemSaving(value) {
        try {
            Toast.show({
                icon: 'loading',
                content: 'Loading…',
                duration: 0
            })
            const param = {
                "amount": redeemAmount,
                "token": selectItem.name,
                "paymentPassword": value
            };
            console.log(param)

            await post(`/saving/redeem?userId=${getQueryVariable("userId")}`, param)

            Toast.clear()
            Toast.show({
                icon: 'success',
                content: "Success",
            })
            setSavingsPaymentVisible(false)
            setSavingsPopupVisible(false)
            setTimeout(() => {
                setReload(!reload)
            }, 3000)
        } catch (error) {
            Toast.show({
                icon: 'fail',
                content: error.message.toString(),
            })
        }
    }


    return (
        <div className="savingsSubcribtionsPage">
            <ListHeader reload={reload} root={false} from="savings" refreshFunc={() => {
                setPage(1)
                setReload(!reload)
            }}/>
            {!!listData && !!listData.content && listData.content.length > 0 && <div className="sectionContainer">
                <p className="title">Staking Orders</p>
                <div className="screen" id="screenId" onClick={() => setStatusSheetVisible(true)}>
                    <img src={data.screen} alt="" className="icon"/>
                    <p className="content">{screen}</p>
                </div>
            </div>
            }
            {
                (!!listData && !!listData.content && listData.content.length !== 0) &&
                <PullToRefresh color={"#ff6c03"} onRefresh={onRefresh} className="refreshHead">
                    <ul className="listData">
                        {
                            listData.content.map((item, index) => {
                                return <li className="dataItem" key={index}>
                                    <div className="headContainer" id="statusId">
                                        <img src={item.iconUrl} alt="" className="icon"/>
                                        <div className="nameAndTime">
                                            <p className="name">{item.assetType}</p>
                                            <p className="time">{moment(item.date).format("YYYY-MM-DD HH:mm:ss")}</p>
                                        </div>
                                        <p className={`status ${item.status === "ACTIVE" ? 'active' : item.status === "FINISHED" ? "completed" : "redeemed"}`}
                                           onClick={() => {
                                               if (item.status === "ACTIVE") {
                                                // Toast.show({ content: "No early redemption \n allowed.", duration: 0})
                                                //    if(item.assetType === 'FCT' && item.day === 180){

                                                //        Toast.show({content: "This order doesn't support redemption."})

                                                //        return
                                                //    }
                                                //    setSelectItem(item)
                                                //    setRedeemSheetVisible(true)
                                               }
                                           }}>{getAaaFormat(item.status)}</p>
                                    </div>
                                    <ul className="contentUl">
                                        <li className="contentLi">
                                            <p className="key">Staking amount</p>
                                            <p className="value">{item.amount}</p>
                                        </li>
                                        <li className="contentLi">
                                            <p className="key">Daily interest</p>
                                            <p className="value">{`${parseFloat(((item.actualDayInterest || 0) * 100).toFixed(6))}%`}</p>
                                        </li>
                                        <li className="contentLi">
                                            <p className="key">24h income</p>
                                            <p className="value">{`${item.earnings24h} ${item.interestTitle}`}</p>
                                            {/* <p className="value">{`${item.earnings24h} FCT`}</p> */}
                                        </li>
                                        <li className="contentLi">
                                            <p className="key">Total income</p>
                                            <p className="value">{`${item.earningsTotal} ${item.interestTitle}`}</p>
                                            {/* <p className="value">{`${item.earningsTotal} FCT`}</p> */}
                                        </li>
                                        <li className="contentLi">
                                            <p className="key">Rate of progress (day)</p>
                                            <p className="value">{`${item.finishedDay} / ${item.day}`}</p>
                                        </li>
                                    </ul>
                                </li>
                            })
                        }
                    </ul>
                    {
                        !listData.last ?
                            <Button fill='none' className="loadMoreButton" loading={loading} onClick={loadMore}>Click
                                load more</Button> : <p className="loadMoreButton">--- no more data ---</p>
                    }
                </PullToRefresh>
            }
            {
                (!listData || !listData.content || listData === null || listData.content.length === 0) &&
                <div className="emptyContainer">
                    <img src={data.subscribtions.noAssetImage} alt="" className="emptyImage"/>
                    <p className="emptyDesc">{data.subscribtions.noAssetDesc}</p>
                </div>
            }
            {!!savingsData &&
                <div className="sectionContainer">
                    <p className="title">Savings Orders</p>

                </div>
            }


            {!!savingsData && <ul className="listData">
                {
                    <li className="dataItem">
                        <div className="headContainer" id="statusId">
                            <img src={savingsData.icon} alt="" className="icon"/>
                            <div className="nameAndTime">
                                <p className="name">{savingsData.name}</p>
                                <p className="time">Flexible</p>
                            </div>
                            <p className={savingsData.savingAmount > 0 ? `status active` : `status no_active`}
                               onClick={() => {
                                   if (savingsData.savingAmount > 0) {
                                       setSelectItem(savingsData)
                                       setSavingsRedeemSheetVisible(true)
                                   }
                               }}>{savingsData.savingAmount > 0 ? getAaaFormat("Active") : getAaaFormat("Not Activated")}</p>
                        </div>
                        <ul className="contentUl">
                            <li className="contentLi">
                                <p className="key">Savings amount</p>
                                <p className="value">{savingsData.savingAmount}</p>
                            </li>
                            <li className="contentLi">
                                <p className="key">Daily interest</p>
                                <p className="value">{`${parseFloat((savingsData.dailyInterest * 100).toFixed(6))}%`}</p>
                            </li>
                            <li className="contentLi">
                                <p className="key">24h income</p>
                                <p className="value">{`${parseFloat(savingsData.earings24h).toFixed(4)} FCT`}</p>
                            </li>
                            <li className="contentLi">
                                <p className="key">Total income</p>
                                <p className="value">{`${parseFloat(savingsData.earingsTotal).toFixed(4)} FCT`}</p>
                            </li>

                        </ul>
                    </li>
                }
            </ul>}
            <ActionSheet visible={statusSheetVisible} actions={[
                {text: 'All', key: 'all'},
                {text: 'Active', key: 'active'}
            ]} onAction={action => {
                setScreen(action.text)
                setStatusSheetVisible(false)
            }} getContainer={document.getElementById("screenId")} onClose={() => setStatusSheetVisible(false)}/>
            <ActionSheet visible={redeemSheetVisible} actions={[
                {text: 'Redeem', key: 'redeem'}
            ]} onAction={() => {
                setRedeemSheetVisible(false)
                setPopupVisible(true)
            }} getContainer={document.getElementById("statusId")} onClose={() => setRedeemSheetVisible(false)}/>

            <ActionSheet visible={savingsRedeemSheetVisible} actions={[
                {text: 'Redeem', key: 'redeem'}
            ]} onAction={() => {
                setSavingsRedeemSheetVisible(false)
                setSavingsPopupVisible(true)
            }} getContainer={document.getElementById("statusId")} onClose={() => setSavingsRedeemSheetVisible(false)}/>


            <Popup visible={popupVisible} getContainer={document.getElementById("statusId")}
                   bodyClassName="redeemBodyContainer" className="redeemPopup">
                {
                    !!selectItem && <div className="container">
                        <img src={data.closeBlack} alt="" className="close" onClick={() => setPopupVisible(false)}/>
                        <img src={data.warmIcon} alt="" className="warnIcon"/>
                        <p className="title">{data.redeem.title}</p>
                        <p className="content">{data.redeem.content}</p>
                        <div className="line"></div>
                        <ul className="list">
                            <li className="itemData">
                                <p className="key">Staking amount</p>
                                <p className="value">{`${selectItem.amount} ${selectItem.assetType}`}</p>
                            </li>
                            <li className="itemData">
                                <p className="key">You will receive</p>
                                <p className="value">{`${parseFloat((selectItem.amount * 0.9).toFixed(6))} ${selectItem.assetType}`}</p>
                            </li>
                            <li className="itemData">
                                <p className="key">Fine</p>
                                <p className="value">{`${parseFloat((selectItem.amount * 0.1).toFixed(6))} ${selectItem.assetType}`}</p>
                            </li>
                        </ul>
                        <Button className="redeemButn" onClick={() => setPaymentVisible(true)}>Still redeem</Button>
                        <p className="cancelButn" onClick={() => setPopupVisible(false)}>Cancel</p>
                    </div>
                }
            </Popup>

            <Popup visible={savingsPopupVisible} getContainer={document.getElementById("statusId")}
                   bodyClassName="redeemBodyContainer" className="redeemPopup" style={{width: "100% !important"}}>

                {
                    !!selectItem && <div className="container">
                        <div className="iconAndName">
                            <img src={selectItem.icon} alt="" className="icon"/>
                            <p className="name">{selectItem.name}</p>
                            <img src={data.closeBlack} alt="" className="close"
                                 onClick={() => setSavingsPopupVisible(false)}/>
                        </div>

                        <div className="stakingTitle">
                            <p className="name">Redemption amount</p>
                            <p className="available">{selectItem.savingAmount}</p>
                        </div>
                        <div className="inputContainer">
                            <Input className="stakingInput" id="stakingInput" ref={stakingAmountRef} type="number"
                                   value={redeemAmount} onChange={(amount) => {
                                const _amount = amount ? amount.toString().match(/^\d+(?:\.\d{0,6})?/) : amount
                                setRedeemAmount(parseFloat(_amount))
                                if (selectItem.savingAmount - _amount >= 100 || selectItem.savingAmount - _amount === 0) {
                                    setRedeemAmountError(false)
                                }

                            }}/>
                            <p className="maxButn" onClick={() => setRedeemAmount(selectItem.savingAmount)}>Max</p>
                        </div>

                        {
                            redeemAmountError && <div className="redeemAmountError">
                                Please redeem all or saving at least 100
                            </div>
                        }


                        <Button className="redeemButn" onClick={() => {
                            console.log(redeemAmount)
                            if (selectItem.savingAmount - redeemAmount < 100 && selectItem.savingAmount - redeemAmount !== 0) {
                                setRedeemAmountError(true)
                                return
                            }
                            setRedeemAmountError(false)
                            setSavingsPaymentVisible(true)
                        }}>Still redeem</Button>
                    </div>
                }

            </Popup>


            <PaymentMask visible={paymentVisible}
                         afterClose={() => {
                             setPaymentVisible(false)
                         }}
                         confirm={(value) => redeem(value)}/>

            <PaymentMask visible={savingsPaymentVisible}
                         afterClose={() => {
                             setSavingsPaymentVisible(false)
                         }}
                         confirm={(value) => redeemSaving(value)}/>
        </div>
    )
}
export default withRouter(SavingsSubcribtionsPage)