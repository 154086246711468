import React, {
  useState,
  useEffect
} from 'react'
import { withRouter } from "react-router-dom"
import { Button, Toast } from 'antd-mobile'
import PullToRefresh from 'react-pullrefresh'
import moment from 'moment'
import { getQueryVariable } from '@tool'
import { constant } from "@json"
import { post } from '@service'
import { ListHeader } from "@component"
import './styles.less'

const RewardsPage = ({ history }) => {
  const data = constant.rewards
  const [page, setPage] = useState(1)
  const [listData, setListData] = useState(null)
  const [reload, setReload] = useState(false)
  const [loading, setLoading] = useState(false)

  function onRefresh() {
    setPage(1)
    setReload(!reload)
  }

  function loadMore() {
    var _page = page + 1
    setPage(_page)
  }

  useEffect(() => {
    async function getData() {
      try {
        Toast.show({
          icon: 'loading',
          content: 'Loading…',
          duration: 0
        })
        setLoading(true)
        var resp = await post(`/staking/rewardHistory?userId=${getQueryVariable("userId")}`, {
          "page": page, "pageSize": 10
        })
        setLoading(false)
        Toast.clear()
        var datas = page === 1 ? [] : listData.content
        resp.content.forEach(element => {
          datas.push(element)
        })
        resp.content = datas
        setListData(resp)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    }
    getData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <div className="rewardsPage">
      <ListHeader root={false} reload={reload} from="rewards" />
      {
        (!!listData && !!listData.content && listData.content.length !== 0) && <PullToRefresh color={"#ff6c03"} onRefresh={onRefresh} className="refreshHead">
          <ul className="listUl">
          {
            listData.content.map((item, index) => {
              return <li className="itemData" key={index}>
                <div className="container">
                  <p className="key">{moment(item.lastUpdate).format("YYYY-MM-DD HH:mm:ss")}</p>
                  <p className="status">{item.tag === "STAKING_INTEREST_DIVIDEND" ? "Dividends" : item.tag === "STAKING_INTEREST_COMMISSION" ? "Commissions" : item.tag}</p>
                </div>
                <div className="line"></div>
                <div className="container">
                  <p className="key">Total</p>
                  <p className="total">{`${item.amount} FCT`}</p>
                </div>
              </li>
            })
          }
          </ul>
          {
            !listData.last ? <Button fill='none' className="loadMoreButton" loading={loading} onClick={loadMore}>Click load more</Button> : <p className="loadMoreButton">--- no more data ---</p>
          }
        </PullToRefresh>
      }
      {
        (!listData || !listData.content || listData === null || listData.content.length === 0) && <div className="emptyContainer">
          <img src={data.noAssetImage} alt="" className="emptyImage" />
          <p className="emptyDesc">{data.noAssetDesc}</p>
        </div>
      }
    </div>
  )
}
export default withRouter(RewardsPage)