import React, {useState, useEffect} from 'react'
import { withRouter } from "react-router-dom"
import PullToRefresh from 'react-pullrefresh'
import { Tabs, Calendar, Toast, Popup, Button } from 'antd-mobile'
import { getQueryVariable } from '@tool'
import moment from 'moment'
import { constant } from "@json"
import { post } from '@service'
import './styles.less' 

const RewardPage = ({ history }) => {
  const data = constant.globalpartner
  const data2 = constant.join
  const [refererPopup, setRefererPopup] = useState(false)
  const [type, setType] = useState("ALL")
  const [menuIndex, setMenuIndex] = useState(0)
  const [customiseTime, setCustomiseTime] = useState([moment(), moment()])
  const [customiseSelect, setCustomiseSelect] = useState([moment(), moment()])
  const [customiseName, setcustomiseName] = useState("")
  const [startTime, setStartTime] = useState()
  const [endTime, setEndTime] = useState()
  const [listData, setListData] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [reload, setReload] = useState(false)

  function onRefresh() {
    setPage(1)
    setReload(!reload)
  }

  function loadMore() {
    var _page = page + 1
    setPage(_page)
  }

  useEffect(() => {
    const _star = moment(customiseTime[0]).format("yyyy-MM-DD")
    const _end = moment(customiseTime[1]).format("yyyy-MM-DD")
    const _value = _star === _end ? _star : `${_star} ~ ${_end}`
    setcustomiseName(_value)
  }, [customiseTime])

  useEffect(() => {
    onRefresh()
    if (menuIndex === 2) {
      var _star = moment(customiseTime[0]).format("yyyy-MM-DD")
      var _star_time = `${_star} 00:00:00`
      var star = moment(_star_time).valueOf()
      var _end = moment(customiseTime[1]).format("yyyy-MM-DD")
      var _end_time = `${_end} 23:59:59`
      var end = moment(_end_time).valueOf()
      setStartTime(star)
      setEndTime(end)
    } else if (menuIndex === 1) {
      const startDate = moment().week(moment().week() - 1).startOf('week').valueOf()
      const endDate = moment().week(moment().week() - 1).endOf('week').valueOf()
      setStartTime(startDate)
      setEndTime(endDate)
    } else {
      const startDate = moment().week(moment().week()).startOf('week').valueOf()
      const endDate = moment().week(moment().week()).endOf('week').valueOf()
      setStartTime(startDate)
      setEndTime(endDate)
    }
  // eslint-disable-next-line
  }, [menuIndex, customiseTime])

  useEffect(() => {
    async function getRewardlist() {
      try {
        Toast.show({
          icon: 'loading',
          content: 'Loading…',
          duration: 0
        })
        setLoading(true)
        const resp = await post(`/app/gpp/rewardlist?userId=${getQueryVariable("userId")}`, {
          "type": type,
          "startTime": startTime,
          "endTime": endTime,
          "page": page,
          "page_size": 10
        })
        setLoading(false)
        Toast.clear()
        var datas = page === 1 ? [] : listData.data
        var _data = resp.data
        if (!!_data) {
          _data.forEach(element => {
            element.timeString = moment(element.time).format("YYYY-MM-DD HH:mm")
            element.ammountString = parseFloat(parseFloat(element.ammount).toFixed(6))
            element.iconString = element.type === "DIVIDENDS" ? data.dividends
                               : element.type === "COMMISSIONS" ? data.commissions
                               : data.rebate
            datas.push(element)
          })
        }
        resp.data = datas
        var _total = parseFloat(parseFloat(resp.total).toFixed(6))
        resp.total = _total
        setListData(resp)
        console.log(resp)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    }
    getRewardlist()
  // eslint-disable-next-line
  }, [startTime, endTime, page, reload, type])
  

  return (
    <div className="reward-page">
      <Tabs className="tab-container" onChange={(key) => {
        setType(key)
        onRefresh()
      }}>
        <Tabs.Tab title='All' key='ALL'></Tabs.Tab>
        <Tabs.Tab title='Swap to Earn' key='SWAPTOEARN'></Tabs.Tab>
        <Tabs.Tab title='Stake to Earn' key='STAKETOEARN'></Tabs.Tab>
      </Tabs>
      <ul className="menu-list">
        <li className={`menu-item ${(menuIndex === 0) ? 'selected' : ''}`} key={0} onClick={() => setMenuIndex(0)}>This Week</li>
        <li className={`menu-item ${(menuIndex === 1) ? 'selected' : ''}`} key={1} onClick={() => setMenuIndex(1)}>Last Week</li>
        <li className={`menu-item ${(menuIndex === 2) ? 'selected' : ''}`} key={2} onClick={() => {
          setMenuIndex(2)
          setRefererPopup(true)
        }}>{
          menuIndex !== 2 ? "Customise" : customiseName
        }</li>
      </ul>
      <p className="number">{listData.total} <span className="symble">USDT</span></p>
      <div className="line"></div>
      {
        (!!listData && !!listData.data && listData.data !== 0) &&  <PullToRefresh color={"#ff6c03"} onRefresh={onRefresh} className="refreshHead">
          <ul className="data-list">
          {
            listData.data.map((item, index) => {
              return <li className="data-item" key={index}>
                <img src={item.iconString} alt="" className="icon" />
                <div className="content-container">
                  <div className="type-time">
                    <p className="type">{item.name}</p>
                    <p className="time">{item.timeString}</p>
                  </div>
                  <p className="record">{`+${item.ammountString} ${item.assets}`}</p>
                </div>
              </li>
            })
          }
          </ul>
          {
            !listData.last ? <Button fill='none' className="loadMoreButton" loading={loading} onClick={loadMore}>Click load more</Button> : <p className="loadMoreButton">--- no more data ---</p>
          }
        </PullToRefresh>
      }
      <Popup visible={refererPopup} onMaskClick={() => setRefererPopup(false)} className="customise-popup-container">
        <div className="customise-container">
          <div className="container">
            <img src={data2.close} alt="" className="close-button" onClick={() => setRefererPopup(false)} />
            <Calendar selectionMode='range' allowClear={false}
              defaultValue={new Date()}
              onChange={val => {
                setCustomiseSelect(val)
              }} />
            <Button className="confirm-button" onClick={() => { 
              setCustomiseTime(customiseSelect)
              setRefererPopup(false)
             }}>Confirm</Button>
          </div>
        </div>
      </Popup>
    </div>
  )
}
export default withRouter(RewardPage)