import React, { 
  useState,
  useEffect,
  useRef
} from 'react'
import { withRouter } from "react-router-dom"
import { Button, Popup, Input, Toast, Dialog } from 'antd-mobile'
import { constant } from "@json"
import { getQueryVariable } from '@tool'
import { post } from '@service'
import { ListHeader, PaymentMask, SuccessMask } from "@component"
import PullToRefresh from 'react-pullrefresh'
import BigNumber from 'bignumber.js'
import moment from 'moment'
import 'moment-timezone'
import './styles.less'

const DepositStakingPage = ({ history }) => {
  const data = constant.deposit
  const [datas, setDatas] = useState(null)
  // const [premiumData, setPremiumData] = useState(null)
  const [savingsDatas, setSavingsDatas] = useState(null)
  const [selectIndex, setSelectIndex] = useState(-1)
  const [selectData, setSelectData] = useState(null)
  const [selectItem, setSelectItem] = useState(-1)
  const [popupVisible, setPopupVisible] = useState(false)
  const [savingPopupVisible, setSavingPopupVisible] = useState(false)
  const [stakingSucessPopupVisible, setStakingSucessPopupVisible] = useState(false)
  const [stakingSucessSubTitle, setStakingSucessSubTitle] = useState(false)
  const [stakingAmount, setStakingAmount] = useState(1)
  const [paymentVisible, setPaymentVisible] = useState(false)
  const [savingsPaymentVisible, setSavingsPaymentVisible] = useState(false)
  const [subscribeDesc, setSubscribeDesc] = useState("")
  const [savingsSubscribeDesc, setSavingsSubscribeDesc] = useState("")
  const [reload, setReload] = useState(false)
  const stakingAmountRef = useRef()

  const nowIsWithinRange = () => {
    const timeZone = 'Asia/Shanghai'
    const startTime = moment.tz("2023-11-24 16:00", timeZone)
    const endTime = moment.tz("2023-11-25 16:00", timeZone)
    const now = moment().tz(timeZone)
    const isWithinRange = now.isBetween(startTime, endTime)
    return isWithinRange
  }

  useEffect(() => {
    if (window.flutterSetNavBarStyle) {
      // none, normal, applet(小程序),
      window.flutterSetNavBarStyle.postMessage("applet") 
    }
  }, [])

  useEffect(() => {
    async function getConfig() {
      try {
        Toast.show({
          icon: 'loading',
          content: 'Loading…',
          duration: 0
        })
        const resp = await post(`/staking/getConfig?userId=${getQueryVariable("userId")}`)

        const userInfo = await post(`/staking/user/info?userId=${getQueryVariable("userId")}`)

        const isRang = nowIsWithinRange()

        const isPrefer = !!userInfo.premium || isRang ? true : false
        const _multiple = isPrefer ? 1.5 : 1.0

        resp.forEach(element => {
          if (element.name === "FCC") {
            element.isRang = isRang
            element.premium = userInfo.premium
            element.list.forEach(data => {
              data.dayInterest2 = data.dayInterest
              data.dayInterestStr2 = data.dayInterestStr
              data.dayInterest = data.dayInterest * _multiple
              data.dayInterestStr = `${parseFloat(Math.floor(parseFloat(data.dayInterestStr) * _multiple * 1000000) / 1000000)}%`
            }) 
          }
        })

        resp.forEach(element => {
          element.dayInterest = 0
          element.showList = false
          element.dayInterestStr = '0.12%'
          element.list.forEach(data => {
            element.dayInterestStr = data.dayInterest > element.dayInterest ? data.dayInterestStr : element.dayInterestStr;
            element.dayInterest = data.dayInterest > element.dayInterest ? data.dayInterest : element.dayInterest;
          })
        })

        let newData = resp
        const specificObject2 = newData.find(item => item.name === "FCT")
        const specificObject = newData.find(item => item.name === "FCC")
        newData = newData.filter(item => item.name !== "FCT" && item.name !== "FCC")
    
        // 然后将对象插入到数组的第二位
        specificObject2 && newData.splice(0, 0, specificObject2);
        specificObject && newData.splice(1, 0, specificObject);
    
        // 更新状态以反映更改
        setDatas(newData)

        const resp1 = await post(`/saving/list?userId=${getQueryVariable("userId")}`)
        setSavingsDatas(resp1)

        // const premiumData = await post(`/saving/status?userId=${getQueryVariable("userId")}`)
        // setPremiumData(premiumData)

        Toast.clear()

      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    }
    if (!!getQueryVariable("userId")) {
      getConfig() 
    }
  }, [reload])

  useEffect(() => {
    if (!!selectData) {
      // const count = (!stakingAmount || parseFloat(stakingAmount) < selectItem?.startNumber) ? "-" : parseFloat((parseFloat(stakingAmount) * selectItem.realDayInterest).toFixed(6))
      const count = !stakingAmount ? "-" : parseFloat((parseFloat(stakingAmount) * parseFloat(selectItem?.startNumber) * selectItem.realDayInterest).toFixed(6))
      var _subscribeDesc = ""
      if (selectItem.assetType === "FCC") {
        _subscribeDesc = `You will receive a daily interest of <br /><span class="lightText">${count} ${selectItem.assetType}</span> per day (in ${selectItem.day}days)`
      } else if (selectItem.assetType === "USD") {
        _subscribeDesc = `Your daily interest of <span class="lightText">${count} ${selectItem.assetType}</span> will be paid to your Futures Card account. `
      } else {
        _subscribeDesc = `You will receive daily interest in <span class="lightText">${selectItem.interestAsset},<br /> equivalent to ${count} ${selectItem.assetType}</span> per day (in ${selectItem.day}days)`
      }
      setSubscribeDesc(_subscribeDesc)
    }
  }, [selectData, selectItem, stakingAmount])

  useEffect(() => {
    if (!!selectData) {
      const count = (!stakingAmount || parseFloat(stakingAmount) < selectItem.minAmmount) ? "-" : parseFloat((parseFloat(stakingAmount) * selectItem.interestRate).toFixed(6))
      var _subscribeDesc = `You will receive a daily interest of <br /><span class="lightText">${count} ${selectItem.token}</span> per day `
      setSavingsSubscribeDesc(_subscribeDesc)
    }
  // eslint-disable-next-line
  }, [selectData, savingsSubscribeDesc, stakingAmount])

  async function checkDeposit() {
    try {
      Toast.show({
        icon: 'loading',
        content: 'Loading…',
        duration: 0
      })
      await post(`/staking/check/deposit?userId=${getQueryVariable("userId")}`,{
        "stakingConfigType": selectItem.stakingConfigType,
        "amount": parseFloat(stakingAmount) * parseFloat(selectItem?.startNumber)
      })
      Toast.clear()
      setPaymentVisible(true)
    } catch (error) {
      Toast.clear()
      if (error.code === 401) {
        Dialog.show({
          content: error.message.toString(),
          closeOnAction: true,
          actions: [
            {
              key: 'confirm',
              text: 'ok',
              className: 'ok-button'
            }
          ],
        })
      } else {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    }
  }

  async function deposit(value) {
    try {
      Toast.show({
        icon: 'loading',
        content: 'Loading…',
        duration: 0
      })
      await post(`/staking/deposit?userId=${getQueryVariable("userId")}`,{
        "stakingConfigType": selectItem.stakingConfigType,
        "amount": parseFloat(stakingAmount) * parseFloat(selectItem?.startNumber),
        "paymentPassword": value
      })
      Toast.clear()
      setPaymentVisible(false)
      setPopupVisible(false)

      if (selectData.name === "FCT") {
        // if(!premiumData.premium){
        //   setTimeout(() => {
        //     setStakingSucessSubTitle("Your FuturesCash Premium is active.")
        //     setStakingSucessPopupVisible(true)
        //   }, 4000)
  
        // }else{
          setStakingSucessSubTitle("Welcome to FuturesCash membership! Your stake is vital to drive our journey forward.")
          setStakingSucessPopupVisible(true)
        // }
      } else {
        Toast.show({
          icon: 'success',
          content: "Success",
        })
      }
      

      setTimeout(() => {
        setReload(!reload)
      }, 3000)
    } catch (error) {
      Toast.clear()
      if (error.code === 401) {
        Dialog.show({
          content: error.message.toString(),
          closeOnAction: true,
          actions: [
            {
              key: 'confirm',
              text: 'ok',
              className: 'ok-button'
            }
          ],
        })
      } else {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    }
  }

  async function savingsDeposit(value) {
    try {
      Toast.show({
        icon: 'loading',
        content: 'Loading…',
        duration: 0
      })
      await post(`/saving/subscribe?userId=${getQueryVariable("userId")}`,{
        "token": selectItem.token,
        "amount": stakingAmount,
        "paymentPIN": value
      })
      Toast.clear()
      Toast.show({
        icon: 'success',
        content: "Success"
      })
      setSavingsPaymentVisible(false)
      setSavingPopupVisible(false)
      setTimeout(() => {
        setReload(!reload)
      }, 3000)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error.message.toString(),
      })
    }
  }

  function onRefresh() {
    setReload(!reload)
  }

  return (
    <div className="depositStakingPage2">
      <ListHeader root={true} from="savings" reload={reload} refreshFunc={() => setReload(!reload)} toPush={() => { history.push(`/savings/subscribtions?userId=${getQueryVariable("userId")}`) }} />
      {/* { !!datas && <p className="sectionTitle">Staking Program</p> } */}
      <p className="sectionTitle"></p>
      {
        !!datas && datas.length > 0 && <PullToRefresh color={"#ff6c03"} onRefresh={onRefresh} className="refreshHead">
           <ul className="depositList">
            {
              datas.map((item, index) => {
                const _interestAsset = (item.list[0]?.assetType === "USD") ? item.list[0]?.assetType : item.list[0]?.interestAsset
                return <li className="dataItem" key={index}>
                  <div className="headContainer" onClick={() => {
                    setSelectIndex(selectIndex === index ? -1 : index)
                  }}>
                    <img src={item.icon} alt="" className="icon" />
                    <div className="nameContainer">
                      <p className={`title ${item.name === "FCT" ? 'fire' : ''}`}>{item.name}</p>
                      <p className="available">{`Interest is paid in: ${_interestAsset}`}</p>
                    </div>
                    <div className={`dailyInterestContainer ${index === selectIndex ? 'none' : 'block'}`}>
                    </div>
                    {
                      (item.isRang || item.premium) && <>{
                        item.isRang ? <div className='extra-div'>
                        <div className='extra-div-item-1'>
                          <img src="https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/deposit/extra-icon-1.png" alt="" />
                          <p>50% Extra APY</p>
                        </div>
                        <div className='extra-div-item-2'>
                          <img src="https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/deposit/extra-icon-2.png" alt="" />
                          <p>Today Only</p>
                        </div>
                      </div> : <div className='extra-div extra-div2'>
                        <div className='extra-div-item-1'>
                          <img src="https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/deposit/extra-icon-3.png" alt="" />
                          <p style={{ color: "#FF7700" }}>50% Extra APY</p>
                        </div>
                        <div className='extra-div-item-2'>
                          <p style={{ color: "#FFAD66" }}>Member Exclusive</p>
                        </div>
                      </div>
                      }</>
                    }
                    {
                      !item.premium && item.name === "FCC" && <div className='extra-div extra-div3'>
                      <div className='extra-div-item-1'>
                        <img src="https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/deposit/extra-icon-1.png" alt="" />
                        <p>50% Extra APY</p>
                      </div>
                      <div className='extra-div-item-2'>
                        <p>Member Exclusive</p>
                      </div>
                    </div> 
                    }
                    {/*<img src={data.direction} alt="" className={`direction ${selectIndex === index ? 'upDirectionAnimation' : selectIndex !== -1 ? 'downDirectionAnimation' : ''}`} />*/}
                  </div>
                  <ul className={`bodyList showBodyListAnimation`} >
                  {
                    item.list.map((type, i) => {
                      return <li className="itemContainer" key={i} id="subscribeId">
                        <div className="content">
                          {/* <p className="interest">{`${type.dayInterestStr}`}</p> */}
                          {/* <p className="interest">{`${ (Math.floor(parseFloat(type.dayInterest * 36000) * 100)/100).toFixed(2) }%`}</p> */}
                          <p className="interest">{`${ (Math.floor(new BigNumber(type.dayInterest).times(36000).times(100))/100).toFixed(2) }%`} {
                            type.dayInterest2 && (item.isRang || item.premium) && <span>{`${ (Math.floor(new BigNumber(type.dayInterest2).times(36000).times(100))/100).toFixed(2) }%`}</span>
                          }</p>
                          <p className="duration">{`${type.day} days | Lot: ${type.startNumber} ${item.name}`}</p>
                        </div>
                        <div className='subscribeBtn-div'>
                          {
                            type.leftDailyLimit !== null && type.leftDailyLimit < type.startNumber ? <Button disabled={true} className="subscribeBtn">Sold Out</Button> : <Button disabled={item.name !== "FCT" && item.name !== "FCC"} className="subscribeBtn" onClick={() => {
                              setSelectData(item)
                              setSelectItem(type)
                              setPopupVisible(true)
                              setTimeout(function(){
                                stakingAmountRef.current.focus()
                              }, 500)
                            }}>Subscribe</Button>
                          }
                          {
                            type.leftDailyLimit !== null && <p className='left-warn'>{`${type.leftDailyLimit} left - Daily Quota`}</p>
                          }
                        </div>
                      </li>
                    })
                  }
                  </ul>
                </li>
              })
            }
            </ul>
        </PullToRefresh>
      }

      { !!savingsDatas && savingsDatas.length > 0 && <p className="sectionTitle">Savings Program</p> }
      <ul className="depositList">
        {
            !!savingsDatas && savingsDatas.length > 0 && savingsDatas.map((item, index) => {
              return <li className="dataItem" key={index}>
                <div className="headContainer" onClick={() => {
                  setSelectIndex(selectIndex === index ? -1 : index)
                }}>
                  <img src={item.icon} alt="" className="icon" />
                  <div className="nameContainer">
                    <p className={`title ${item.token === "FCT" ? 'fire' : ''}`}>{item.token}</p>
                    <p className="available">{`Min: ${item.minAmmount}`}</p>
                  </div>

                </div>
                <ul className={`bodyList showBodyListAnimation`} >

                  <li className="itemContainer" id="subscribeId">
                    <div className="content">
                      {/* <p className="interest">{`${type.dayInterestStr}`}</p> */}
                      <p className="interest">{`${ (Math.floor( parseFloat(item.yearinterestRate * 100) *100)/100).toFixed(2)}%`}</p>
                      <p className="duration">{`Flexible`}</p>
                    </div>
                    <Button disabled className="subscribeBtn" onClick={() => {
                      setSelectData(item)
                      setSelectItem(item)
                      setSavingPopupVisible(true)
                      setTimeout(function(){
                        stakingAmountRef.current.focus()
                      }, 500)
                    }}>Subscribe</Button>
                  </li>

                </ul>
              </li>
            })
        }
      </ul>




      <Popup visible={popupVisible} forceRender={true} getContainer={document.getElementById("subscribeId")} afterClose={() => setStakingAmount("")} bodyClassName="subcribeBodyContainer" className="subscribePopup">
      {
        !!selectData && <div className="container">
          <div className="iconAndName">
            <img src={selectData.icon} alt="" className="icon" />
            <p className="name">{selectData.name}</p>
            <img src={data.closeBlack} alt="" className="close" onClick={() => setPopupVisible(false)} />
          </div>
          <ul className="interestAndPeroid">
            <li className="interestAndPeroidContainer">
              <p className="key">Daily interest</p>
              <p className="value">{`${selectItem.dayInterestStr}`}</p>
            </li>
            <li className="interestAndPeroidContainer">
              <p className="key">Peroid</p>
              <p className="value">{`${selectItem.day} days`}</p>
            </li>
          </ul>
          <div className="line"></div>
          <div className="stakingTitle">
            <p className="name">{`${selectItem?.startNumber} ${selectData.name}/Lot`}</p>
            <p className="available">{selectData.available}</p>
          </div>
          <div className="inputContainer">
            <Input className="stakingInput" id="stakingInput" ref={stakingAmountRef} placeholder='Lot' type="number" value={stakingAmount} onChange={(amount) => {
              const _amount = amount ? amount.toString().match(/^\d+(?:\.\d{0,0})?/) : amount
              setStakingAmount(parseFloat(_amount))
            }} />
            <p className="maxButn" onClick={() => setStakingAmount(Math.floor(selectData.available / selectItem?.startNumber))}>Max</p>
          </div>
          {
            <p className='total'>{`${!stakingAmount ? '-' : stakingAmount * selectItem?.startNumber}`}</p>
          }
          <p className="desc" dangerouslySetInnerHTML={{__html: subscribeDesc}}></p>
          <Button className="subscribeButn" disabled={!stakingAmount} onClick={() => {
            checkDeposit()
          }}>Subscribe</Button>
        </div>
      }
      </Popup>

      <Popup visible={savingPopupVisible} forceRender={true} getContainer={document.getElementById("subscribeId")} afterClose={() => setStakingAmount("")} bodyClassName="subcribeBodyContainer" className="subscribePopup">
        {
            !!selectData && <div className="container">
              <div className="iconAndName">
                <img src={selectData.icon} alt="" className="icon" />
                <p className="name">{selectData.token}</p>
                <img src={data.closeBlack} alt="" className="close" onClick={() => setSavingPopupVisible(false)} />
              </div>
              <ul className="interestAndPeroid">
                <li className="interestAndPeroidContainer">
                  <p className="key">Daily interest</p>
                  <p className="value">{`${parseFloat(parseFloat(selectItem.interestRate * 100).toFixed(6))}%`}</p>
                </li>
                <li className="interestAndPeroidContainer">
                  <p className="key">Peroid</p>
                  <p className="value">{`Flexible`}</p>
                </li>
              </ul>
              <div className="line"></div>
              <div className="stakingTitle">
                <p className="name">Staking amount</p>
                <p className="available">{selectData.available}</p>
              </div>
              <div className="inputContainer">
                <Input className="stakingInput" id="stakingInput" ref={stakingAmountRef} placeholder={`Min:${selectItem.minAmmount} ${selectItem.token}`} type="number" value={stakingAmount} onChange={(amount) => {
                  const _amount = amount ? amount.toString().match(/^\d+(?:\.\d{0,4})?/) : amount
                  setStakingAmount(parseFloat(_amount))
                }} />
                <p className="maxButn" onClick={() => setStakingAmount(selectData.available)}>Max</p>
              </div>
              <p className="desc" dangerouslySetInnerHTML={{__html: savingsSubscribeDesc}}></p>
              <Button className="subscribeButn" disabled={stakingAmount.length === 0 || parseFloat(stakingAmount) < selectItem.minAmmount} onClick={() => setSavingsPaymentVisible(true)}>Subscribe</Button>
            </div>
        }
      </Popup>


      <PaymentMask visible={paymentVisible}
        afterClose={() => { setPaymentVisible(false) }}
        confirm={(value) => deposit(value)} />


      <PaymentMask visible={savingsPaymentVisible}
                   afterClose={() => { setSavingsPaymentVisible(false) }}
                   confirm={(value) => savingsDeposit(value)} />


      <SuccessMask visible={stakingSucessPopupVisible}
                   subTitle={stakingSucessSubTitle}
                   afterClose={() => { setStakingSucessPopupVisible(false) }}
                   confirm={(value) => setStakingSucessPopupVisible(false)}
      learnMore={()=>{
        history.push(`/premium?userId=${getQueryVariable("userId")}`)
        setStakingSucessPopupVisible(false)
      }}/>


    </div>
  )
}
export default withRouter(DepositStakingPage)
