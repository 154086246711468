import axios from 'axios'
import { BASE_URL, TIMEOUT, ERROR_OBJECT, REKALL_BASE_URL } from './config'

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: TIMEOUT
})
// 请求拦截
instance.interceptors.request.use(
  config => {
    config.data = JSON.stringify(config.data)
    config.headers = {
      "Content-Type": "application/json",
      "Authorization": localStorage.getItem("authorization-session"),
    }
    return config
  }, 
  error => {
    ERROR_OBJECT.code = 100
    ERROR_OBJECT.message = error
    return Promise.reject(ERROR_OBJECT)
  }
)
// 响应拦截
instance.interceptors.response.use(
  res => {
    if (res.status === 200) {
      let response = res.data
      if (response.code === 0) {
        return response.data
      } else if (response.code === 100046 || response.code === 100048) {
        ERROR_OBJECT.code = 401
        ERROR_OBJECT.message = response.message
        return Promise.reject(ERROR_OBJECT)
      } else {
        ERROR_OBJECT.code = 400
        ERROR_OBJECT.message = response.message
        return Promise.reject(ERROR_OBJECT)
      }
    } else {
      ERROR_OBJECT.code = 300
      ERROR_OBJECT.message = res.statusText
      return Promise.reject(ERROR_OBJECT)
    }
  }, 
  error => {
    ERROR_OBJECT.code = 200
    ERROR_OBJECT.message = error
    return Promise.reject(ERROR_OBJECT)
  }
)
// 请求方法
export const post = function post(url, params = {}) {
  return new Promise((resolve, reject) => {
    instance.post(url,params).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export const get = function get(url) {
 return axios.get(`${REKALL_BASE_URL}${url}`)
}