import React, {useState, useEffect} from 'react'
import { withRouter } from "react-router-dom"
import { Input, Button, Toast, Mask } from 'antd-mobile'
import { post } from '@service'
import { dataLetterSort } from '@tool'
import { constant } from "@json"
import { useTranslation } from "react-i18next"
import './styles.less' 

const RegisterPage = ({ history }) => {
  const data = constant.globalpartner
  const data2 = constant.join
  const [referralCode, setReferralCode] = useState("")
  const [type, setType] = useState('email')
  const [value, setValue] = useState("")
  const [countryCodeInfo, setCountryCodeInfo] = useState()
  const [countryCodeData, setCountryCodeData] = useState(null)
  const [maskModal, setMaskModal] = useState(false)
  const [onFocus, setOnFocus] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (!!history.location && !!history.location.state) {
      const _state = history.location.state
      setReferralCode(_state.code)
    }
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let registerStorage = JSON.parse(sessionStorage.getItem("register-session"))
    if (!!registerStorage) {
      setCountryCodeInfo(registerStorage.countryCodeInfo)
      setType(registerStorage.type)
      setValue(registerStorage.inputValue)
    } else {
      // setCountryCodeInfo({id: '', alphaCode: 'US', numberCode: '1', name: 'United State'})
      // setType("phone")
      // setValue("")
    }
  }, [])

  async function getCountryCodeData() {
    try {
      Toast.show({
        icon: 'loading',
        content: t("Loading…"),
        duration: 0
      })
      const resp = await post("/app/countryCode/list")
      Toast.clear()
      let datas = []
      resp.forEach(element => {
        if (element.numberCode !== "86") {
          datas.push(element)
        }
      })
      var result = dataLetterSort(datas)
      setCountryCodeData(result)
      setMaskModal(true)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error.message.toString(),
      })
    }
  }
  
  async function checkAccount() {
    const inputValue = value.trim()
    if (!inputValue) {
      Toast.show({
        icon: 'fail',
        content: t("Please input your account"),
      })
    } else if (type === "phone") {
      try {
        Toast.show({
          icon: 'loading',
          content: t("Loading…"),
          duration: 0
        })
        const resp = await post("/app/account/checkAccount", {
          "countryCode": countryCodeInfo.numberCode,
          "mobile": inputValue
        })
        Toast.clear()
        if (!!resp.exitCashUser) {
          Toast.show({
            icon: 'fail',
            content:  t("Account already exists"),
          })
        } else if (!!resp.exitPiFuturesUser) {
          Toast.show({
            icon: 'fail',
            content: t("PiFutures already have the same account"),
          })
        } else {
          // sessitionStorageEvent()
          // isNeedCaptcha({"mobile": inputValue, "countryCode": countryCodeInfo.numberCode, "email": ""})
        }
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    } else {
      try {
        Toast.show({
          icon: 'loading',
          content: t("Loading…"),
          duration: 0
        })
        const resp = await post("/app/account/checkAccount/email", {
          "email": inputValue
        })
        Toast.clear()
        if (!!resp.exitCashUser) {
          Toast.show({
            icon: 'fail',
            content: t("Account already exists")
          })
        } else if (!!resp.exitPiFuturesUser) {
          Toast.show({
            icon: 'fail',
            content: t("PiFutures already have the same account"),
          })
        } else {
          isNeedCaptcha({"email": inputValue, "mobile": "", "countryCode": "", "username": ""})
          sessitionStorageEvent()
        }
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    }
  }

  async function isNeedCaptcha(data) {
    try {
      const resp = await post("/app/captcha/needCaptcha", data)  
      if (resp.type === "TENCENT") {
        initTencentCaptcha().show()
      } else {
        window.grecaptcha.ready(function() {
          window.grecaptcha.execute("6LdldPwZAAAAACfK_nyA9IJ42FBtiNojpqURLQl4", {action: "submit"}).then(function(token) {
            const inputValue = value.trim()
            if (type === "phone") {
              history.push({
                pathname: "/verifyaccount", 
                state: { 
                  type: "phone",
                  referralCode: referralCode,
                  account: inputValue,
                  countryCode: countryCodeInfo.numberCode,
                  alphaCode: countryCodeInfo.alphaCode,
                  countryName: countryCodeInfo.name,
                  geetestData: token,
                  captchaType: "RECAPTCHA"
                }
              })
            } else {
              history.push({
                pathname: "/verifyaccount", 
                state: { 
                  type: "email",
                  referralCode: referralCode,
                  account: inputValue,
                  geetestData: token,
                  captchaType: "RECAPTCHA"
                }
              })
            }
          })
        })
      }
    } catch (error) {
      console.log(error)
      Toast.show({
        icon: 'fail',
        content: error.message.toString(),
      })
    }
  }

  function initTencentCaptcha() {
    return new window.TencentCaptcha("2038742797", (res) => {
      const inputValue = value.trim()
      if (type === "phone") {
        history.push({
          pathname: "/verifyaccount", 
          state: { 
            type: "phone",
            referralCode: referralCode,
            account: inputValue,
            countryCode: countryCodeInfo.numberCode,
            alphaCode: countryCodeInfo.alphaCode,
            countryName: countryCodeInfo.name,
            geetestData: res,
            captchaType: "TENCENT"
          }
        })
      } else {
        history.push({
          pathname: "/verifyaccount", 
          state: { 
            type: "email",
            referralCode: referralCode,
            account: inputValue,
            geetestData: res,
            captchaType: "TENCENT"
          }
        })
      }
    }, {})
  }

  function sessitionStorageEvent() {
    const inputValue = value.trim()
    sessionStorage.setItem("register-session", JSON.stringify({
      countryCodeInfo: countryCodeInfo,
      inputValue: inputValue,
      type: type
    }))
  }

  return (
    <div className="register-page">
      <div className="nav-bar" >
        <img src={data.arrow02} alt="" className="back-button" onClick={() => history.goBack()} />
      </div>
      <p className="title">{ t("Create Account") }</p>
      <div className="select-container">
        {/* <p className={`select-menu ${type === "phone" ? "active" : ""}`} onClick={() => setType("phone")}>{ t("Phone") }</p>
        <p className={`select-menu ${type === "email" ? "active" : ""}`} onClick={() => setType("email")}>{ t("邮箱") }</p> */}
        <p className="select-menu active" onClick={() => setType("email")}>{ t("邮箱") }</p>
      </div>
      <div className={`input-container ${onFocus ? 'input-container-active' : ''}`}>
        {
          type === "phone" && <p className="country-code" onClick={() => {
            if (!!countryCodeData) {
              setMaskModal(true)
            } else {
              getCountryCodeData()
            }
          }}>{`+${countryCodeInfo.numberCode}`}</p>
        }
        <Input value={value} onFocus={() => setOnFocus(true)} onBlur={() => setOnFocus(false)} onChange={val => { setValue(val) }} className="input-div" />
      </div>
      <p className="referral-id">{ t("Referral ID") }</p>
      <div className="referral-id-container">{referralCode}</div>
      <Button className="next-button" onClick={() => checkAccount()}>{ t("Next") }</Button>
      <Mask visible={maskModal} forceRender={true} opacity='0.5' className="mask-container" >
        <div className="bg-container">
          <div className="head-container">
            <div className="empty-div"></div>
            <p className="title">{ t("Country Code") }</p>
            <img src={data2.close} alt="" className="close-button" onClick={() => setMaskModal(false)} />
          </div>
          {
            !!countryCodeData && <div className="body-container">
              <div className="body-scroll">
                <ul className="content-list">
                {
                  Object.keys(countryCodeData).map((item, index) => {
                    return <li className="section-container" key={index}>
                      <p className="section-key">{item === "#" ? "Popular" : item}</p>
                      <ul className="section-value-list">
                      {
                        countryCodeData[item].map((codeData, idx) => {
                          return <li key={idx} className="section-value-item" onClick={() => {
                            setCountryCodeInfo(codeData)
                            setMaskModal(false)
                          }}>
                            <p className="value-name">{codeData.name}</p>
                            <p className="value-code">{`+${codeData.numberCode}`}</p>
                            {
                              countryCodeInfo.name === codeData.name && <img src={data2.checked} alt="" className="checked" />
                            }
                          </li>
                        })
                      }
                      </ul>
                    </li>
                  })
                }
                </ul>
              </div>
            </div>
          }
        </div>
      </Mask>
    </div>
  )
}
export default withRouter(RegisterPage)