import React, { 
  useState,
  useEffect
} from 'react'
import { withRouter } from "react-router-dom"
import { Toast } from 'antd-mobile'
import CopyToClipboard from 'react-copy-to-clipboard'
import { constant } from "@json"
import { toGetInviteCode, openUrl } from '@tool'
import axios from 'axios'
import './styles.less'

const InvitePage = ({ history }) => {
  const data = constant.invite
  const [inviteCode, setInviteCode] = useState("")
  const [androidUrl, setAndroidUrl] = useState("")

  useEffect(() => {
    const _inviteCode = toGetInviteCode(history.location.pathname)
    setInviteCode(_inviteCode)
  }, [history.location.pathname])

  function copyCode(){
    if (!!inviteCode) {
      Toast.show({
        icon: 'success',
        content: 'Copied Success',
        maskClassName: "inviteCopyCodeToast"
      })
    }
  }

  useEffect(() => {
    async function getAndroidUrl() {
      try {
        axios.post("https://api.pifutures.org/app/info/get", {},{
          headers: {
            "device": "web",
            "channel": "web"
          }
        }).then((res) => {
          if (res.status === 200) {
            let response = res.data
            if (response.code === 0) {
              var data = response.data
              var _androidUrl = data.androidUrl//.replace("https://pifutures.oss-cn-shanghai.aliyuncs.com/download","https://download.futures.cash")
              setAndroidUrl(_androidUrl)
            } else {
              Toast.show({
                icon: 'fail',
                content: response.message.toString(),
              })
            }
          } else {
            Toast.show({
              icon: 'fail',
              content: "network error",
            })
          }
        })
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    }
    getAndroidUrl()
  }, [])

  return (
    <div className="invitePage">
      <img src={data.headImage} alt="" className="headImage" />
      <div className="body">
        <p className="title">{data.title}</p>
        <p className="content" dangerouslySetInnerHTML={{__html: data.content}}></p>
        <CopyToClipboard text={inviteCode} onCopy={() => copyCode() }>
          <div className="code">{inviteCode}</div>
        </CopyToClipboard>
        <ul className="buttonList">
        {
          data.buttons.map((item, index) => {
            return <img src={item.image} alt="" key={index} className="buttonItem" onClick={() => {
              if (index === 2) {
                if (!!androidUrl) {
                  openUrl(androidUrl)
                } else {
                  return
                }
              }
              else {
                openUrl(item.link)
              }
            }} />
          })
        }
        </ul>
      </div>
    </div>
  )
}
export default withRouter(InvitePage)