import React from 'react'
import { Image } from 'antd-mobile'
import './styles.less'

const Artworks = (props) => {

  return (
    <ul className="artworks">
    {
      props.datas.map((item, index) => {
        return <li key={index} className="artworks-item" onClick={() => props.push(item.id)}>
          <div className="artworks-item-image-div">
            <Image fit="cover" src={item.thumbnails.small} alt="" className="artworks-item-image" />
            {/* {
              item === 0 && <div className="artworks-item-shade"></div>
            }
            {
              item === 0 && <p className="artworks-item-soldout">Sold Out</p>
            } */}
          </div>
          <p className="artworks-item-number">{item.title}</p>
          <p className="artworks-item-name">{item.product_collection[0]?.name}</p>
          <p className={`artworks-item-price ${item === 0 ? 'artworks-item-soldout' : ''}`}>{`$${item.price}`}</p>
        </li>
      })
    }
    </ul>
  )
}

const Collections = (props) => {
  return (
    <ul className="collections">
    {
      props.datas.map((item, index) => {
        return <li key={index} className="collections-item" onClick={() => props.push(item.id)}>
          <img src={item.banner} alt="" className="collections-item-image" />
          <div className="collections-item-container">
            <img src={item.thumbnails.small} alt="" className="collections-item-icon" />
            <div className="collections-item-content">
              <p className="collections-item-name">{item.name}</p>
              <p className="collections-item-number">{`${item.count} Items`}</p>
            </div>
          </div>
        </li>
      })
    }
    </ul>
  )
}

const MarketplaceFoot = () => {
  return (
    <div className="foot">Powered by<img src="https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/marketplace/rekall.png" alt="" className="foot-image" /></div>
  )
}

const NavBar = (props) => {
  return (
    <div className="navbar">
      <img src={
        props.modal ? "https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/marketplace/close.png" 
        : "https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/marketplace/back-black.png"
      } alt="" className="back-button" onClick={() => props.back()} />
    </div>
  )
}

export { Artworks, Collections, MarketplaceFoot, NavBar }