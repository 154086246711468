import React, {
  useState,
  useEffect
} from 'react'
import { Toast } from 'antd-mobile'
import { post } from '@service'
import { getQueryVariable } from '@tool'
import PullToRefresh from 'react-pullrefresh'
import { constant } from "@json"
import './styles.less'

const ListHeader = (props) => {
  const data = constant.headList
  const [isRoot, setRoot] = useState(props.root)
  const [isFrom, setFrom] = useState(props.from)
  const [headData, setHeadData] = useState(null)
  const [reload, setReload] = useState(false)

  useEffect(() => {
    setRoot(props.root)
  }, [props.root])

  useEffect(() => {
    setFrom(props.from)
  }, [props.from])

  useEffect(() => {
    if (isFrom === "deposit") {
      const _headData = data.deposit
      async function getInfo() {
        try {
          Toast.show({
            icon: 'loading',
            content: 'Loading…',
            duration: 0
          })
          const resp = await post(`/staking/info?userId=${getQueryVariable("userId")}`)
          Toast.clear()
          _headData.total.value = resp.balance
          _headData.contentL.value = resp.earnings
          _headData.contentR.value = resp.earnings24h
          setHeadData(_headData)
        } catch (error) {
          setHeadData(_headData)
          Toast.show({
            icon: 'fail',
            content: error.message.toString(),
          })
        }
      }
      if (!!getQueryVariable("userId")) {
        getInfo()
      }
    } if (isFrom === "savings") {
      const _headData = data.deposit
      async function getInfo() {
        try {
          Toast.show({
            icon: 'loading',
            content: 'Loading…',
            duration: 0
          })
          const resp = await post(`/staking/info/v2?userId=${getQueryVariable("userId")}`)
          Toast.clear()
          _headData.total.value = resp.balance
          _headData.contentL.value = resp.earnings
          _headData.contentR.value = resp.earnings24h
          setHeadData(_headData)
        } catch (error) {
          setHeadData(_headData)
          Toast.show({
            icon: 'fail',
            content: error.message.toString(),
          })
        }
      }
      if (!!getQueryVariable("userId")) {
        getInfo()
      }
    } else if (isFrom === "rewards") {
      const _headData = data.rewards
      async function getRewardTotal() {
        try {
          Toast.show({
            icon: 'loading',
            content: 'Loading…',
            duration: 0
          })
          const resp = await post(`/staking/rewardTotal?userId=${getQueryVariable("userId")}`)
          Toast.clear()
          _headData.total.value = `$ ${resp.total}`
          _headData.contentL.value = `${resp.dividend} FCT`
          _headData.contentR.value = `${resp.commission} FCT`
          setHeadData(_headData)
        } catch (error) {
          setHeadData(_headData)
          Toast.show({
            icon: 'fail',
            content: error.message.toString(),
          })
        }
      }
      if (!!getQueryVariable("userId")) {
        getRewardTotal()
      }
    } else {
      setHeadData(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFrom, getQueryVariable("userId"), props.reload, reload])

  function onRefresh() {
    setReload(!reload)
    props.refreshFunc()
  }

  return (
    <PullToRefresh color={"#ff6c03"} onRefresh={onRefresh} className="refreshHead">
      <div className="listHeader" style={{backgroundImage: `url(${data.headImage})`}}>
      {
        !!headData && <>
          <div className="head">
            <div className="text">
              <p className="txt">{headData.total.key}</p>
              <p className="number">{headData.total.value}</p>
            </div>
            { isRoot && <img src={data.arrow} alt="" className="arrow" onClick={ props.toPush } /> }
          </div>
          <ul className="footUl">
            <li className="itemLi">
              <p className="name">{headData.contentL.key}</p>
              <p className="content">{headData.contentL.value}</p>
            </li>
            <li className="itemLi">
              <p className="name">{headData.contentR.key}</p>
              <p className="content">{headData.contentR.value}</p>
            </li>
          </ul>
        </>
      }
    </div>
    </PullToRefresh>
  )
}
export default ListHeader