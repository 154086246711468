import React, {
  useState,
  useEffect
} from 'react'
import { Button, Mask } from 'antd-mobile'
import { constant } from "@json"
import './styles.less'

const SuccessMask = (props) => {
  const data = constant.private
  const prenium = constant.prenium
  const [visible, setVisible] = useState(false)
  const [subTitle, setSubTitle] = useState(false)

  useEffect(() => {
    setVisible(props.visible)
  }, [props.visible])

  useEffect(() => {
    setSubTitle(props.subTitle)
  }, [props.subTitle])

  return (
    <Mask visible={visible} forceRender={true} opacity='0.5' className="successMask" afterClose={() => {
      props.afterClose()
    }} >
      <div className="contentDiv">
        <div className="toolContainer"><img src={data.closeIcon} alt="" className="closeButn" onClick={() => setVisible(false)} /></div>
        <img src={prenium.successLogo} alt="" className="bg-image" style={{ marginTop: "0px" }}/>
        <p className="applyTitle">Thank you!</p>
        <p className="applySubTitle">{subTitle}</p>

        <Button block className="confirmBtn" onClick={() => {
          props.confirm()
        }}>OK</Button>
        {/* <p className="learnMore" onClick={() => {
          props.learnMore()
        }}>Learn More</p> */}
      </div>
    </Mask>
  )
}

export default SuccessMask