import React from 'react'
import { withRouter } from "react-router-dom"
import { Button } from 'antd-mobile'
import { getQueryVariable } from '@tool'
import { constant } from "@json"
import './styles.less' 

const BeGlobalPartnerPage = ({ history }) => {
  const data = constant.globalpartner

  function invite() {
    window.futuresCashShare.postMessage("message from JS...")
  }

  return (
    <div className="be-global-partner-page">
      <img src={data.newpartnership} alt="" className="bg-image" />
      <p className="rules-button" onClick={() => window.open("https://support.futures.cash/hc/en-us/articles/7671425429273-Global-PartnerShip-Program")}>Rules</p>
      {/* <div className="form-container">
        <div className="form-image-scroll">
          <img src={data.form} alt="" className="form-image" />
        </div>
      </div> */}
      <div className="button-container">
        <div className="invite-button-container" onClick={() => {
          history.push(`/invitation?userId=${getQueryVariable("userId")}`)
        }}>
          <img src={data.invitation} alt="" className="invite-icon" />
          <p className="invite-text">Invitation</p>
        </div>
        <Button className="button" onClick={() => invite()}>Join Us</Button>
      </div>
    </div>
  )
}
export default withRouter(BeGlobalPartnerPage)