import React from 'react'
import { withRouter } from "react-router-dom"
import { constant } from "@json"
import './styles.less' 

const RekallProfilePage = ({ history }) => {
  const data = constant.upgrade

  return (
    <div className="rekall-profile-page">
      <img src={data.bg} alt="" className="bg" />
      <p dangerouslySetInnerHTML={{__html: data.text}} className="text"></p>
    </div>
  )
}
export default withRouter(RekallProfilePage)