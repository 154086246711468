import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom"
import React from "react"
import { Main } from '@component'
import { 
  Private, Private2, Invite, Rule, DepositStaking, DepositSubcribtions, 
  Rewards, Freecharge, ToGlobalPartner, BeGlobalPartner, Register, Join, 
  VerifyAccount, SetPassword, SetUsername, Welcome, Invitation, Reward, 
  Upgrade, Rekall, MarketPlace, Artwork, Collection, Creator, Result, Premium,
  SavingsStaking, SavingsSubcribtions,
  Task,
  Explore
} from '@src/page'
import "@config/i18n"
import './App.less'

function App() {
  
  return (
    <Router> 
      <Switch>
        <Main>
          <Route path="/private"><Private /></Route>
          <Route path="/private2"><Private2 /></Route>
          <Route path="/invite"><Invite /></Route>
          <Route path="/rule"><Rule /></Route>
          <Route path="/deposit/staking"><DepositStaking /></Route>
          <Route path="/deposit/subscribtions"><DepositSubcribtions /></Route>
          <Route path="/rewards"><Rewards /></Route>
          <Route path="/freecharge"><Freecharge /></Route>
          <Route path="/toGlobalPartner"><ToGlobalPartner /></Route>
          <Route path="/beGlobalPartner"><BeGlobalPartner /></Route>
          <Route path="/premium"><Premium /></Route>
          <Route path="/savings/staking"><SavingsStaking /></Route>
          <Route path="/savings/subscribtions"><SavingsSubcribtions /></Route>

          <Route path="/join"><Join /></Route>
          <Route path="/register"><Register /></Route>
          <Route path="/verifyaccount"><VerifyAccount /></Route>
          <Route path="/setpassword"><SetPassword /></Route>
          <Route path="/setusername"><SetUsername /></Route>
          <Route path="/welcome"><Welcome /></Route>
          <Route path="/invitation"><Invitation /></Route>
          <Route path="/reward"><Reward /></Route>
          <Route path="/intheupgrade"><Upgrade /></Route>
          <Route path="/rekall"><Rekall /></Route>
          <Route path="/marketplace"><MarketPlace /></Route>
          <Route path="/artwork"><Artwork /></Route>
          <Route path="/collection"><Collection /></Route>
          <Route path="/creator"><Creator /></Route>
          <Route path="/result"><Result /></Route>

          <Route path="/task"><Task /></Route>
          <Route path="/explore"><Explore /></Route>
        </Main>
      </Switch>
    </Router>
  )
}

export default App