import React from 'react'
import { withRouter } from "react-router-dom"
import './styles.less' 

const ResultPage = ({ history }) => {

  function toMyOrders() {
    window.jumpTo.postMessage("/my_rekall_orders")
    history.goBack()
  }

  return (
    <div className="result-page">
      <img src="https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/marketplace/success.png" alt="" className="icon" />
      <p className="title">Congratulations!</p>
      <p className="desc">Your order is being processed and <br /> will be completed shortly</p>
      <div className="back-butn" onClick={() => history.goBack()}>Back</div>
      <div className="view-butn" onClick={() => toMyOrders()}>View Orders</div>
    </div>
  )
}
export default withRouter(ResultPage)