import React, {useState, useEffect} from 'react'
import { withRouter } from "react-router-dom"
import { Mask, ProgressBar, Button, Popup, Toast } from 'antd-mobile'
import moment from 'moment'
import { getQueryVariable } from '@tool'
import { post } from '@service'
import { constant } from "@json"
import './styles.less' 

const ToGlobalPartnerPage = ({ history }) => {
  const data = constant.globalpartner
  const [assessment, setAssessment] = useState("This Week")
  const [assessmentModal, setAssessmentModal] = useState(false)
  const [assessmentPopup, setAssessmentPopup] = useState(false)
  const [menuIndex, setMenuIndex] = useState(0)
  const [rewards, setRewards] = useState("This Week")
  const [rewardsPopup, setRewardsPopup] = useState(false)
  const [detail, setDetail] = useState(null)

  useEffect(() => {
    async function getDetail() {
      try {
        Toast.show({
          icon: 'loading',
          content: 'Loading…',
          duration: 0
        })
        const resp = await post(`/app/gpp/detail?userId=${getQueryVariable("userId")}`)
        resp.gradeIcon = resp.level === 1 ? data.grade01
                       : resp.level === 2 ? data.grade02
                       : resp.level === 3 ? data.grade03
                       : resp.level === 4 ? data.grade04
                       : resp.level === 5 ? data.grade05
                       : resp.level === 6 ? data.grade06
                       : data.grade06
        resp.gradeLV = resp.level === 1 ? data.LV1
                     : resp.level === 2 ? data.LV2
                     : resp.level === 3 ? data.LV3
                     : resp.level === 4 ? data.LV4
                     : resp.level === 5 ? data.LV5
                     : resp.level === 6 ? data.LV6
                     : data.LV6
        setDetail(resp)
        Toast.clear()
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    }
    if (!!getQueryVariable("userId")) {
      getDetail() 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function invite() {
    window.futuresCashShare.postMessage("message from JS...")
  }

  return (
    <>
    {
      !!detail ? <div className="to-global-partner-page">
        <div className="head-container">
          <img src={detail.gradeIcon} alt="" className="icon" />
          <div className="text-container">
            <p className="identity">{detail.levelName}</p>
            <p className="time">{`Until ${moment(detail.expiredTime).format("YYYY-MM-DD HH:mm:ss")}`}</p>
          </div>
          <div className="rules-container" onClick={() => history.push(`beGlobalPartner?userId=${getQueryVariable("userId")}`)}>
            <p className="text">Rules</p>
            <img src={data.arrow01} alt="" className="icon" />
          </div>
        </div>
        <div className="block">
          <div className="title-container">
            <div className="title_l">
              <p className="title">Assessment</p>
              <img src={data.arrow06} alt="" className="icon" onClick={() => setAssessmentModal(true)} />
            </div>
            <div className="select-container" onClick={() => setAssessmentPopup(true)}>
              <p className="select-name">{ assessment }</p>
              <img src={data.arrow04} alt="" className="select-icon" />
            </div>
          </div>
          <div className="content-container">
            <div className="content-l">
              {
                assessment === "This Week" ? <p className="content-number">{ detail.thisWeekAssessment }</p>
                : <p className="content-number">{ detail.lastWeekAssessment }</p>
              }
              <p className="content-symbol">USDT</p>
            </div>
            {
              !detail ? <p className="content-r">-</p> : <p className="content-r">{detail.requestAssessment}</p>
            }
          </div>
          <div className="process-container margin-bottom-div">
            {
              assessment === "This Week" ? <ProgressBar percent={Math.min((detail.thisWeekAssessment * 100 / detail.requestAssessment), 100)} className="process" />
              : <ProgressBar percent={Math.min((detail.lastWeekAssessment * 100 / detail.requestAssessment), 100)} className="process" />
            }
            <img src={detail.gradeLV} alt="" className="icon" />
          </div>
          <div className="line"></div>
        </div>
        <div className="block">
          <div className="title-container">
            <div className="title_l">
              <p className="title">Invitation</p>
            </div>
          </div>
          <div className="content-container margin-bottom-div" onClick={() => history.push(`invitation?userId=${getQueryVariable("userId")}`)}>
            <div className="content-l">
              <p className="content-number">{detail.invitation}</p>
            </div>
            <img src={data.arrow03} alt="" className="content-push-icon" />
          </div>
          <div className="line"></div>
        </div>
        <div className="block">
          <div className="title-container">
            <div className="title_l">
              <p className="title">Rewards</p>
            </div>
            <div className="select-container" onClick={() => setRewardsPopup(true)}>
              <p className="select-name">{ rewards }</p>
              <img src={data.arrow04} alt="" className="select-icon" />
            </div>
          </div>
          <ul className="menu-list">
          {
            ["All", "Swap to Earn", "Stake to Earn"].map((item, index) => {
              return <li className={`menu-item ${menuIndex === index ? 'selected' : ''}`} key={index} onClick={() => setMenuIndex(index)}>{ item }</li>
            })
          }
          </ul>
          <div className="content-container margin-bottom-div" onClick={() => history.push(`reward?userId=${getQueryVariable("userId")}`)}>
            <div className="content-l">
              <p className="content-number">{
                rewards === "This Week" && menuIndex === 0  ? detail.thisWeekAllReward 
                : rewards === "This Week" && menuIndex === 1 ? detail.thisWeekSwapToEarnReward 
                : rewards === "This Week" && menuIndex === 2 ? detail.thisWeekStakeToEarnReward
                : rewards === "Last Week" && menuIndex === 0 ? detail.lastWeekAllReward 
                : rewards === "Last Week" && menuIndex === 1 ? detail.lastWeekSwapToEarnReward 
                : rewards === "Last Week" && menuIndex === 2 ? detail.lastWeekStakeToEarnReward
                : "-"
              }</p>
              <p className="content-symbol">USDT</p>
            </div>
            <img src={data.arrow03} alt="" className="content-push-icon" />
          </div>
          <div className="line"></div>
        </div>
        <div style={{height: "66px"}}></div>
        <div className="invite-container">
          <Button className="button" onClick={() => invite()}>Invite Friends</Button>
        </div>
        <Mask visible={assessmentModal} forceRender={true} opacity='0.5' className="mask-container" >
          <div className="content-container">
            <p className="text" dangerouslySetInnerHTML={{__html: "Net inflow of yourself, your invitees, and the invitees of your invitees. <span class=\"bold\">Assessment = Total Deposit - Total Withdrawal.</span> (If assessment is negative, it will be shown as 0.)"}}></p>
            <Button className="confirm-button" onClick={() => setAssessmentModal(false)}>Confirm</Button>
          </div>
        </Mask>
        <Popup visible={assessmentPopup} onMaskClick={() => setAssessmentPopup(false)} className="popup-container">
          <div className="select-container">
            <Button fill='none' className={`select-menu ${ assessment === "This Week" ? 'selected' : '' }`} onClick={() => {
              setAssessment("This Week")
              setAssessmentPopup(false)
            }}>This Week</Button>
            <Button fill='none' className={`select-menu ${ assessment === "Last Week" ? 'selected' : '' }`} onClick={() => {
              setAssessment("Last Week")
              setAssessmentPopup(false)
            }}>Last Week</Button>
            <Button fill='none' className="select-menu" onClick={() => setAssessmentPopup(false)}>Cancle</Button>
          </div>
        </Popup>
        <Popup visible={rewardsPopup} onMaskClick={() => setRewardsPopup(false)} className="popup-container">
          <div className="select-container">
            <Button fill='none' className={`select-menu ${ rewards === "This Week" ? 'selected' : '' }`} onClick={() => {
              setRewards("This Week")
              setRewardsPopup(false)
            }}>This Week</Button>
            <Button fill='none' className={`select-menu ${ rewards === "Last Week" ? 'selected' : '' }`} onClick={() => {
              setRewards("Last Week")
              setRewardsPopup(false)
            }}>Last Week</Button>
            <Button fill='none' className="select-menu" onClick={() => setRewardsPopup(false)}>Cancle</Button>
          </div>
        </Popup>
      </div> : <div className="to-global-partner-page">
        <div className="head-container">
          <img src={data.grade01} alt="" className="icon" />
          <div className="text-container">
            <p className="identity">-</p>
            {
              !detail ? <p className="time">Until -</p> 
              : <p className="time">{`Until ${moment(detail.expiredTime).format("YYYY-MM-DD HH:mm:ss")}`}</p>
            }
          </div>
          <div className="rules-container">
            <p className="text">Rules</p>
            <img src={data.arrow01} alt="" className="icon" />
          </div>
        </div>
        <div className="block">
          <div className="title-container">
            <div className="title_l">
              <p className="title">Assessment</p>
              <img src={data.arrow06} alt="" className="icon" onClick={() => setAssessmentModal(true)} />
            </div>
            <div className="select-container" onClick={() => setAssessmentPopup(true)}>
              <p className="select-name">{ assessment }</p>
              <img src={data.arrow04} alt="" className="select-icon" />
            </div>
          </div>
          <div className="content-container">
            <div className="content-l">
              <p className="content-number">-</p>
              <p className="content-symbol">USDT</p>
            </div>
            <p className="content-r">-</p>
          </div>
          <div className="process-container margin-bottom-div">
            <ProgressBar percent={0} className="process" /> 
            <img src={data.LV1} alt="" className="icon" />
          </div>
          <div className="line"></div>
        </div>
        <div className="block">
          <div className="title-container">
            <div className="title_l">
              <p className="title">Invitation</p>
            </div>
          </div>
          <div className="content-container margin-bottom-div">
            <div className="content-l">
              <p className="content-number">-</p>
            </div>
            <img src={data.arrow03} alt="" className="content-push-icon" />
          </div>
          <div className="line"></div>
        </div>
        <div className="block">
          <div className="title-container">
            <div className="title_l">
              <p className="title">Rewards</p>
            </div>
            <div className="select-container" onClick={() => setRewardsPopup(true)}>
              <p className="select-name">{ rewards }</p>
              <img src={data.arrow04} alt="" className="select-icon" />
            </div>
          </div>
          <ul className="menu-list">
          {
            ["All", "Swap to Earn", "Stake to Earn"].map((item, index) => {
              return <li className={`menu-item ${menuIndex === index ? 'selected' : ''}`} key={index} onClick={() => setMenuIndex(index)}>{ item }</li>
            })
          }
          </ul>
          <div className="content-container margin-bottom-div">
            <div className="content-l">
              <p className="content-number">-</p>
              <p className="content-symbol">USDT</p>
            </div>
          </div>
          <div className="line"></div>
        </div>
        <div style={{height: "66px"}}></div>
        <div className="invite-container">
          <Button className="button" onClick={() => invite()}>Invite Friends</Button>
        </div>
      </div>
    }
    </>
  )
}
export default withRouter(ToGlobalPartnerPage)