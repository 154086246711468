import React, {useState, useEffect} from 'react'
import { withRouter } from "react-router-dom"
import { Toast } from 'antd-mobile'
import { constant } from "@json"
import { openUrl } from '@tool'
import axios from 'axios'
import { useTranslation } from "react-i18next"
import './styles.less' 

const WelcomePage = ({ history }) => {
  const data = constant.join
  const data2 = constant.globalpartner
  const [androidUrl, setAndroidUrl] = useState("")
  const { t, i18n } = useTranslation()

  const fzUrl = "https://futures-cash.s3.us-west-1.amazonaws.com/app/FuturesCash-mainland-realse.apk"

  useEffect(() => {
    async function getAndroidUrl() {
      try {
        Toast.show({
          icon: 'loading',
          content: t("Loading…"),
          duration: 0
        })
        axios.post("https://api.pifutures.org/app/info/getV2", {},{
          headers: {
            "device": "web",
            "channel": "web"
          }
        }).then((res) => {
          Toast.clear()
          if (res.status === 200) {
            let response = res.data
            if (response.code === 0) {
              var data = response.data
              var _androidUrl = data.androidUrl
              setAndroidUrl(_androidUrl)
            } else {
              Toast.show({
                icon: 'fail',
                content: response.message.toString(),
              })
            }
          } else {
            Toast.show({
              icon: 'fail',
              content: t("network error"),
            })
          }
        })
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    }
    getAndroidUrl()
  // eslint-disable-next-line
  }, [])

  return (
    <div className="welcome-page">
      <div className="nav-bar" >
        <img src={data2.arrow02} alt="" className="back-button" onClick={() => history.goBack()} />
      </div>
      <div className="head-container">
        <img src={data.logo} alt="" className="logo" />
        <div className="push-container" onClick={() => window.open("https://futures.cash/")}>
          <p className="website">{ t("Official Website") }</p>
          <img src={data.arrow} alt="" className="arrow" />
        </div>
      </div>
      <div className="body-container">
        <p className="title">{`🎉 ${t("Welcome to FuturesCash!")}`}</p>
        <p className="content">{ t("Get started with FuturesCash now! Simply download the App and sign in.") }</p>
        <div className="button-container">
          {
            i18n.language !== 'en' &&  <img src={data.fz} alt="" className="download-button" onClick={() => openUrl(fzUrl)} />
          }
          <img src={data.appstore} alt="" className="download-button" onClick={() => openUrl("https://apps.apple.com/us/app/futurescash/id1578643022")} />
          {
            i18n.language === 'en' && <img src={data.googleplay} alt="" className="download-button" onClick={() => openUrl("https://play.google.com/store/apps/details?id=cash.futures.app")} />
          }
          <img src={data.android} alt="" className="download-button" onClick={() => openUrl(androidUrl)} />
         {
          i18n.language === 'en' &&  <img src={data.fz} alt="" className="download-button" onClick={() => openUrl(fzUrl)} />
         }
        </div>
      </div>
    </div>
  )
}
export default withRouter(WelcomePage)