export function openUrl(url) {
  window.open(url)
}
// 获取路由中某个参数
export function getQueryVariable(variable) {
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i=0;i<vars.length;i++) {
		var pair = vars[i].split("=");
		if(pair[0] === variable){return decodeURIComponent(pair[1]);}
	}
	return(false);
}
export const toGetInviteCode = (pathname) => {
  var names = pathname.split("/")
  if (names === undefined || names === null || names.length === 0) {
    return null;
  }
  var index = names.findIndex(item => item === "invite")
  if (index === -1) {
    return null;
  }
  if (index + 1 > names.length - 1) {
    return null;
  }
  return names[index + 1]
}
export const getAaaFormat = (string) => {
  if (string === "REDEEM_FINISHED") {
    return "Redeemed"
  } else if (string === "FINISHED") {
    return "Completed"
  } else if (string === "ACTIVE") {
    return "Active"
  } else {
    return string
  }
}
export const dataLetterSort = (data) => {
  var letter_reg = /^[A-Z]$/
  var list = []
  list['#'] = []
  for (var i = 0; i < data.length; i++) {
    // 首字母 转 大写英文
    var letter = (data[i]["name"]).substr(0, 1).toUpperCase()
    // 是否 大写 英文 字母
    if (!letter_reg.test(letter) || data[i]["id"] === "") {
      letter = '#'
    }
    // 创建 字母 分组
    if (!(letter in list)) {
      list[letter] = []
    }
    // 字母 分组 添加 数据
    list[letter].push(data[i])
  }
  // 转换 格式 进行 排序；
  var resault = []
  for (var key in list) {
    resault.push({
      letter: key,
      list: list[key]
    })
  }
  resault.sort(function (x, y) {
    return x.letter.charCodeAt(0) - y.letter.charCodeAt(0)
  })
  // # 号分组 放最后
  // var last_arr = resault[0]
  // resault.splice(0, 1)
  // resault.push(last_arr)

  // 转换 数据 格式
  var json_sort = {}
  for (var index = 0; index < resault.length; index++) {
    json_sort[resault[index].letter] = resault[index].list
  }

  return json_sort
}
export function numFormat(num) {
  if (num === undefined || num === null || !num) {
    return "0"
  }
  num = num.toString().split('.')
  let arr = num[0].split('').reverse()
  let res = []
  for (var i = 0; i < arr.length; i++) {
    if (i % 3 === 0 && i !== 0){
      res.push(',')
    }
    res.push(arr[i])
  }
  res.reverse()
  if (num[1]) {
    res = res.join('').concat('.'+num[1]);
  } else {
    res = res.join('')
  }
  return res
}