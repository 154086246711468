import React, {useState} from 'react'
import { withRouter } from "react-router-dom"
import { Input, Button, Toast } from 'antd-mobile'
import { constant } from "@json"
import { post } from '@service'
import { useTranslation } from "react-i18next"
import './styles.less' 

const SetUsernamePage = ({ history }) => {
  const data = constant.globalpartner
  const [value, setValue] = useState("")
  const [onFocus, setOnFocus] = useState(false)
  const { t } = useTranslation()

  async function setusername() {
    const _value = value.trim()
    if (!_value) {
      Toast.show({
        icon: 'fail',
        content: t("Please input your username"),
      })
    } else {
      try {
        Toast.show({
          icon: 'loading',
          content: t("Loading…"),
          duration: 0
        })
        const data = {
          "username": value
        }
        await post("/app/account/editUsername", data)
        Toast.clear()
        Toast.show({
          icon: 'success',
          content: t("Success")
        })
        history.push("/welcome")
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    }
  }

  return (
    <div className="set-username-page">
      <div className="nav-bar" >
        <img src={data.arrow02} alt="" className="back-button" onClick={() => history.goBack()} />
      </div>
      <p className="title">{ t("Set Your Username") }</p>
      <p className="content">{ t("Username cannot be changed after setting up.") }</p>
      <div className={`input-container ${onFocus ? 'input-container-active' : ''}`}>
        <p className="sign">$</p>
        <Input clearable value={value} onFocus={() => setOnFocus(true)} onBlur={() => setOnFocus(false)} onChange={val => { setValue(val) }} className="input-div" />
      </div>
      <Button className="done-button" onClick={() => setusername()}>{ t("Done") }</Button>
      <Button fill='none' className="skip-button" onClick={() => history.push("/welcome")}>{ t("Skip") }</Button>
    </div>
  )
}
export default withRouter(SetUsernamePage)