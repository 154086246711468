import React, { 
  useState,
  useEffect
} from 'react'
import { withRouter } from "react-router-dom"
import PullToRefresh from 'react-pullrefresh'
import { ActionSheet, Popup, Button, Toast } from 'antd-mobile'
import moment from 'moment'
import { constant } from "@json"
import { post } from '@service'
import { getAaaFormat, getQueryVariable } from '@tool'
import { ListHeader, PaymentMask } from "@component"
import './styles.less'

const DepositSubcribtionsPage = ({ history }) => {
  const data = constant.deposit
  const [listData, setListData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [statusSheetVisible, setStatusSheetVisible] = useState(false)
  const [screen, setScreen] = useState("All")
  const [redeemSheetVisible, setRedeemSheetVisible] = useState(false)
  const [popupVisible, setPopupVisible] = useState(false)
  const [paymentVisible, setPaymentVisible] = useState(false)
  const [page, setPage] = useState(1)
  const [selectItem, setSelectItem] = useState(null)
  const [reload, setReload] = useState(false)

  function onRefresh() {
    setPage(1)
    setReload(!reload)
  }

  function loadMore() {
    var _page = page + 1
    setPage(_page)
  }

  useEffect(() => {
    async function getData() {
      const param = screen === "All" ? {
        "page": page, "pageSize": 10
      } : {
        "page": page, "pageSize": 10, "status": screen.toUpperCase()
      }
      try {
        Toast.show({
          icon: 'loading',
          content: 'Loading…',
          duration: 0
        })
        setLoading(true)
        var resp = await post(`/staking/depositHistory?userId=${getQueryVariable("userId")}`, param)
        setLoading(false)
        Toast.clear()
        var datas = page === 1 ? [] : listData.content
        resp.content.forEach(element => {
          datas.push(element)
        })
        resp.content = datas
        setListData(resp)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    }
    getData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, screen, reload])

  async function redeem(value) {
    try {
      Toast.show({
        icon: 'loading',
        content: 'Loading…',
        duration: 0
      })
      await post(`/staking/redeem?userId=${getQueryVariable("userId")}`, {
        "stakingId": selectItem.id,
        "paymentPassword": value
      })
      Toast.clear()
      Toast.show({
        icon: 'success',
        content: "Success",
      })
      setPaymentVisible(false)
      setPopupVisible(false)
      setTimeout(() => {
        setReload(!reload)
      }, 3000)
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: error.message.toString(),
      })
    }
  }

  return (
    <div className="depositSubcribtionsPage">
      <ListHeader reload={reload} root={false} from="deposit" />
      <div className="sectionContainer">
        <p className="title">Orders</p>
        <div className="screen" id="screenId" onClick={() => setStatusSheetVisible(true)}>
          <img src={data.screen} alt="" className="icon" />
          <p className="content">{screen}</p>
        </div>
      </div>
      {
        (!!listData && !!listData.content && listData.content.length !== 0) &&  <PullToRefresh color={"#ff6c03"} onRefresh={onRefresh} className="refreshHead">
          <ul className="listData">
          {
            listData.content.map((item, index) => {
              return <li className="dataItem" key={index}>
                <div className="headContainer" id="statusId">
                  <img src={item.iconUrl} alt="" className="icon" />
                  <div className="nameAndTime">
                    <p className="name">{item.assetType}</p>
                    <p className="time">{moment(item.date).format("YYYY-MM-DD HH:mm:ss")}</p>
                  </div>
                  <p className={`status ${item.status === "ACTIVE" ? 'active' : item.status === "FINISHED" ? "completed" : "redeemed"}`} 
                  onClick={() => {
                    if (item.status === "ACTIVE") {
                      setSelectItem(item)
                      setRedeemSheetVisible(true)
                    }
                  }}>{getAaaFormat(item.status)}</p>
                </div>
                <ul className="contentUl">
                  <li className="contentLi">
                    <p className="key">Staking amount</p>
                    <p className="value">{item.amount}</p>
                  </li>
                  <li className="contentLi">
                    <p className="key">Daily interest</p>
                    <p className="value">{`${parseFloat((item.dayInterest  * 100).toFixed(6))}%`}</p>
                  </li>
                  <li className="contentLi">
                    <p className="key">24h income</p>
                    <p className="value">{`${item.earnings24h} ${item.interestTitle}`}</p>
                  </li>
                  <li className="contentLi">
                    <p className="key">Total income</p>
                    <p className="value">{`${item.earningsTotal} ${item.interestTitle}`}</p>
                  </li>
                  <li className="contentLi">
                    <p className="key">Rate of progress (day)</p>
                    <p className="value">{`${item.finishedDay} / ${item.day}`}</p>
                  </li>
                </ul>
              </li>
            })
          }
          </ul>
          {
            !listData.last ? <Button fill='none' className="loadMoreButton" loading={loading} onClick={loadMore}>Click load more</Button> : <p className="loadMoreButton">--- no more data ---</p>
          }
        </PullToRefresh>
      }
      {
        (!listData || !listData.content || listData === null || listData.content.length === 0) && <div className="emptyContainer">
          <img src={data.subscribtions.noAssetImage} alt="" className="emptyImage" />
          <p className="emptyDesc">{data.subscribtions.noAssetDesc}</p>
        </div>
      }
      <ActionSheet visible={statusSheetVisible} actions={[
        { text: 'All', key: 'all' },
        { text: 'Active', key: 'active' }
      ]} onAction={action => {
        setScreen(action.text)
        setStatusSheetVisible(false)
      }} getContainer={document.getElementById("screenId")} onClose={() => setStatusSheetVisible(false)} />
      <ActionSheet visible={redeemSheetVisible} actions={[
        { text: 'Redeem', key: 'redeem' }
      ]} onAction={() => {
        setRedeemSheetVisible(false)
        setPopupVisible(true)
      }} getContainer={document.getElementById("statusId")} onClose={() => setRedeemSheetVisible(false)} />
      <Popup visible={popupVisible} getContainer={document.getElementById("statusId")} bodyClassName="redeemBodyContainer" className="redeemPopup">
      {
        !!selectItem && <div className="container">
          <img src={data.closeBlack} alt="" className="close" onClick={() => setPopupVisible(false)} />
          <img src={data.warmIcon} alt="" className="warnIcon" />
          <p className="title">{data.redeem.title}</p>
          <p className="content">{data.redeem.content}</p>
          <div className="line"></div>
          <ul className="list">
            <li className="itemData">
              <p className="key">Staking amount</p>
              <p className="value">{`${selectItem.amount} ${selectItem.assetType}`}</p>
            </li>
            <li className="itemData">
              <p className="key">You will receive</p>
              <p className="value">{`${parseFloat((selectItem.amount * 0.9).toFixed(6))} ${selectItem.assetType}`}</p>
            </li>
            <li className="itemData">
              <p className="key">Fine</p>
              <p className="value">{`${parseFloat((selectItem.amount * 0.1).toFixed(6))} ${selectItem.assetType}`}</p>
            </li>
          </ul>
          <Button className="redeemButn" onClick={() => setPaymentVisible(true)}>Still redeem</Button>
          <p className="cancelButn" onClick={() => setPopupVisible(false)}>Cancel</p>
        </div>
      }
      </Popup>
      <PaymentMask visible={paymentVisible} 
        afterClose={() => { setPaymentVisible(false) }}
        confirm={(value) => redeem(value)} />
    </div>
  )
}
export default withRouter(DepositSubcribtionsPage)