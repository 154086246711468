import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom"
import IMAGE from './image'
import { getQueryVariable } from '@tool'
import { post } from '@service'
import { Toast } from 'antd-mobile'
import './styles.less' 

const IndexPage = ({ history }) => {
  const token = getQueryVariable("token")
  const [data, setData] = useState(null)
  const [isReady, setReady] = useState(false)
  const [reload, setReload] = useState(false)

  useEffect(() => {
    !!token && localStorage.setItem("authorization-session", token)
    async function getData() {
      try {
        Toast.show({
          icon: 'loading',
          content: 'Loading…',
          duration: 0
        })
        const resp = await post("campaign/get_all_user_campaign")
        Toast.clear()
        setData(resp)
        !!resp?.isLevel2 && setReady(true)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    }
    !!token && getData()
  }, [token, reload])

  useEffect(() => {
    async function checkin() {
      try {
        Toast.show({
          icon: 'loading',
          content: 'Loading…',
          duration: 0
        })
        await post("campaign/check_in")
        Toast.clear()
        Toast.show({
          icon: 'success',
          content: "Sign in successfully"
        })
        setReload(!reload)
      } catch (error) {
        Toast.show({
          icon: 'fail',
          content: error.message.toString(),
        })
      }
    }
    isReady && checkin()
  // eslint-disable-next-line
  }, [isReady])

  // window.rechargeSuccess = () => {
  //   Toast.show({
  //     icon: 'success',
  //     content: "Recharge successfully"
  //   })
  // }

  return (
    <div className="task-page">
      <div className="head" style={{ backgroundImage: `url(${IMAGE.gradient})` }}>
        <div className="navbar">
          {/* <img src={IMAGE.arrowLeftWhite} alt="" style={{ opacity: '1.0' }} onClick={() => {
            // if (window.closeWebview) {
            //   window.closeWebview.postMessage("message from JS...")
            // }
            if (window.openSwap) {
              window.openSwap.postMessage("message from JS...")
            }
          }} /> */}
          <img src={IMAGE.arrowLeftWhite} alt="" style={{ opacity: '0' }} />
        </div>
        <p className="amount">{`${data?.totalReward || '0'} ${data?.totalRewardToken || ''}`}</p>
        <div className="txt">
          <img src={IMAGE.star1} alt="" />
          <p>Your Rewards</p>
          <img src={IMAGE.star2} alt="" />
        </div>
      </div>
      {
        !!data?.checkInTask && data?.checkInTask.length > 0 && <div className="session">
          <p className="title">Daily Check-in</p>
          <div className="list">
          {
            data?.checkInTask.map((item, index) => {
              return <div key={index} className="item" style={{ backgroundColor: item.isCheckIn ? "#E7F9F5" : "#F7F7F7" }}>
                <p className="day" style={{ color: item.isCheckIn ? "#10C29F" : "#808080" }}>{`Day ${index + 1}`}</p>
                <img src={item.isCheckIn ? IMAGE.checked : IMAGE.gold} alt="" />
                <p className="amount" style={{ color: item.isCheckIn ? "#10C29F" : "#212121" }}>{`+${item.amount}`}</p>
              </div>
            })
          }
          </div>
          <div className="line" />
        </div>
      }
      <Session name="Daily Tasks" datas={data?.dailyTask} isLevel2={data?.isLevel2} />
      <Session name="Weekly Tasks" datas={data?.weeklyTask} isLevel2={data?.isLevel2} />
      <Session name="Beginner Tasks" datas={data?.onceTask} isLevel2={data?.isLevel2} />
    </div>
  )
}
export default withRouter(IndexPage)


const Session = (props) => {
  const { name, datas, isLevel2 } = props

  return !!datas && datas.length > 0 ? <div className="session">
    <p className="title">{ name }</p>
    <div className="list2">
    {
      datas.map((item, index) => {
        return <div className="item2" key={index}>
          <div className="t1">{ item.name.substring(0, 2) }</div>
          <p className="t2">{ item.name.substring(2) }</p>
          <div className="amount-button" style={{ backgroundColor: !isLevel2 ? "#FFBB80" : item.isDone ? "#E7F9F5" : "#F70" }}>
            <img src={!isLevel2 || !item.isDone ? IMAGE.gold : IMAGE.checked2} alt="" />
            <p style={{ color:  !isLevel2 || !item.isDone ? "#fff" : "#10C29F" }}>{`+${item.rewardAmount}`}</p>
          </div>
        </div>
      })
    }
    </div>
    <div className="line" />
  </div> : <></>
}