import React from 'react'
import { withRouter } from "react-router-dom"
import './styles.less' 

const ResultPage = ({ history }) => {

  function rekall() {
      window.open("https://rekall.net/")
  }

  function close() {
      window.closeWebview.postMessage("message from JS...");
  }

  return (
    <div className="result-page">
      <img src="https://pifutures.oss-cn-shanghai.aliyuncs.com/FuturesCashWeb/marketplace/rekall_logo.png" alt="" className="icon" />
      <p className="title">Coming Soon</p>
      <p className="desc">Visit <span style={{color:"#5100ED"}}>rekall.net</span> and earn rewards.</p>
      <div className="back-butn" onClick={() => rekall()}>Go to Rekall</div>
      <div className="view-butn" onClick={() => close()}>Back</div>
    </div>
  )
}
export default withRouter(ResultPage)